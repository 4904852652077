import React from "react";
import logError from "@utils/logError";
import HomepageErrorContainer from "./HomepageErrorContainer";
import HomePage from "./index";

function HomePageContainer(props) {

    const { hasError } = props;

    if (hasError) {
        return (
            <HomepageErrorContainer {...props} />
        );
    }
    return <HomePage {...props} />;
}
class HomePageWithErrorBounday extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(e, info) {
        let errormessage = JSON.stringify(e.message);
        let infoStackTrace = JSON.stringify(info.componentStack);
        let errorDetails = JSON.stringify(e.stack);
        let windowUrl = window.location.href;
        infoStackTrace =
            errormessage +
            "\n" +
            windowUrl +
            "\n" +
            infoStackTrace +
            "\n" +
            errorDetails;
        let errorLog = {
            message: infoStackTrace,
        };
        console.error("Problems in Home Page PROCESSING:", e);
        logError(errorLog);
    }
    render() {
        const { hasError } = this.state;
        return <HomePageContainer hasError={hasError} {...this.props} />;
    }
}
export default HomePageWithErrorBounday;