/* eslint jsx-a11y/href-no-hash: 0 */
// ^ Turn this off after hooking up data

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem } from '@lib/List'

// import SafeIcon from './icons/secure.svg'
import FbIcon from './icons/fb.svg'
import TwitterIcon from './icons/twitter.svg'
import InstagramIcon from './icons/instagram.svg'
import LinkdinIcon from './icons/linkdin.svg'
import { path } from '@ui/utils'

class GlobalFooter extends PureComponent {
    render() {
        const { children } = this.props
        const currentYear = new Date().getFullYear()
        return (
            <footer>
                {children && <ListItem className="pt-10 pr-0 bb-0 pl-12 pr-0 list-style-none">{children}</ListItem>}
                <List className="mt-8 pt-20 bt-0 pb-80 pl-16 pr-16">
                    <ListItem className="flex flex-start flex-wrap pb-15 pl-0 pr-0">
                        <a href="/about" className="c-blue fs-14 lh-copy pr-15 pb-10">
                            About Us
                        </a>
                        <a
                            rel="nofollow"
                            href="http://www.cleartrip.com/jobs"
                            className="c-blue fs-14 lh-copy pr-15 pb-10"
                        >
                            Careers
                        </a>
                        <a rel="nofollow" href="/faq" className="c-blue fs-14 lh-copy pr-15 pb-10">
                            FAQs
                        </a>
                        <a rel="nofollow" href="/support" className="c-blue fs-14 lh-copy pr-15 pb-10">
                            Support
                        </a>
                        <a rel="nofollow" href="/collections" className="c-blue fs-14 lh-copy pr-15">
                            Collections
                        </a>
                        <a
                            rel="nofollow"
                            href="http://www.cleartrip.com/giftcards"
                            className="c-blue fs-14 lh-copy pr-15"
                        >
                            Gift cards
                        </a>
                        <a rel="nofollow" href="/referral/" className="c-blue fs-14 lh-copy pr-15">
                            Referral Program
                        </a>
                    </ListItem>
                    <ListItem className="pt-20 pb-20 pl-0 pr-0">
                        <p className="fs-12 c-black-70 lh-copy pb-10">© 2006–{currentYear} Cleartrip MEA FZ LLC.</p>
                        <div className="flex flex-between flex-middle">
                            <div className="flex flex-between">
                                <a rel="nofollow" href="/privacy" className="fs-12 c-black-70 lh-copy pr-15">
                                    Privacy
                                </a>
                                <a rel="nofollow" href="/security" className="fs-12 c-black-70 lh-copy pr-15">
                                    Security
                                </a>
                                <a rel="nofollow" href="/terms" className="fs-12 c-black-70 lh-copy pr-15">
                                    Terms of Use
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-between flex-middle pt-10">
                            <div className="">
                                <a rel="nofollow" href="https://facebook.com/CleartripArabia" aria-label="Facebook">
                                    <FbIcon fill="#A5A5A5" className="mr-16" />
                                </a>
                                <a href="http://twitter.com/cleartrip" rel="nofollow" aria-label="Twitter">
                                    <TwitterIcon fill="#A5A5A5" className="mr-16" />
                                </a>
                            </div>
                        </div>
                    </ListItem>

                    {/* <ListItem className="pt-10 pb-5 bb-0 pl-0 pr-0">
                        <p className="c-black-60 fw-500 fs-12 lh-copy pb-8">Important Links</p>
                        <div className="flex flex-wrap">
                            <a href="/flights" className="fs-10 c-black-50 lh-copy pr-15  d-ib pb-5 ">
                                Flight tickets
                            </a>
                            <a href="/flights" className="fs-10 c-black-50 lh-copy pr-15  d-ib pb-5 ">
                                Cheap flights
                            </a>
                            <a
                                href="/flight-schedule/flight-schedules-domestic.html"
                                className="fs-10 c-black-50 lh-copy pr-15  d-ib pb-5 "
                            >
                                Domestic flights
                            </a>
                            <a href="/hotels" className="fs-10 c-black-50 lh-copy pr-15  d-ib pb-5 ">
                                Hotels
                            </a>
                        </div>
                    </ListItem> */}
                </List>
            </footer>
        )
    }
}

GlobalFooter.propTypes = {
    children: PropTypes.string
}

GlobalFooter.defaultProps = {}

export default GlobalFooter
