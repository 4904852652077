import React from 'react'
interface Props {
    selected: boolean
}
export const HomeIcon = ({ selected }: Props) => {
    if (selected) {
        return (
            <svg viewBox="0 0 20 20" width="20">
                <path
                    d="M17.44 1.39l.04.042a1.538 1.538 0 010 2.115l-4.172 4.392 2.646 7.511a.506.506 0 01-.057.45l-.06.073-1.646 1.663-3.935-6.483-3.968 4.178.741 1.864c.064.16.04.34-.055.477l-.055.065L5.67 19l-1.87-2.926-2.898-1.887 1.251-1.263a.494.494 0 01.537-.111l1.426.577 4.128-4.346-6.618-4.096L3.27 3.286a.494.494 0 01.518-.118l7.483 2.687 4.2-4.423a1.382 1.382 0 011.967-.041z"
                    fill="#FFF"
                    id="prefix__flights"
                    stroke="none"
                    stroke-width="1"
                    fill-rule="evenodd"
                ></path>
            </svg>
        )
    } else {
        return (
            <svg viewBox="0 0 20 20" width="20">
                <path
                    d="M17.44 1.39l.04.042a1.538 1.538 0 010 2.115l-4.172 4.392 2.646 7.511a.506.506 0 01-.057.45l-.06.073-1.646 1.663-3.935-6.483-3.968 4.178.741 1.864c.064.16.04.34-.055.477l-.055.065L5.67 19l-1.87-2.926-2.898-1.887 1.251-1.263a.494.494 0 01.537-.111l1.426.577 4.128-4.346-6.618-4.096L3.27 3.286a.494.494 0 01.518-.118l7.483 2.687 4.2-4.423a1.382 1.382 0 011.967-.041z"
                    fill="#B3B3B3"
                    id="prefix__flights"
                    stroke="none"
                    stroke-width="1"
                    fill-rule="evenodd"
                ></path>
            </svg>
        )
    }
}

export const HotelsIcon = ({ selected }: Props) => {
    if (selected) {
        return (
            <svg viewBox="0 0 20 20" width="20">
                <path
                    d="M1.25 3c.69 0 1.25.56 1.25 1.25V12H20v5h-2.5v-2.5h-15V17H0V4.25C0 3.56.56 3 1.25 3zM19 6a1 1 0 011 1v4H5a1 1 0 010-2h3V7a1 1 0 011-1h10zM5.503 4.5a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5z"
                    fill="#FFF"
                    id="prefix__hotels"
                    stroke="none"
                    stroke-width="1"
                    fill-rule="evenodd"
                ></path>
            </svg>
        )
    } else {
        return (
            <svg viewBox="0 0 20 20" width="20">
                <path
                    d="M1.25 3c.69 0 1.25.56 1.25 1.25V12H20v5h-2.5v-2.5h-15V17H0V4.25C0 3.56.56 3 1.25 3zM19 6a1 1 0 011 1v4H5a1 1 0 010-2h3V7a1 1 0 011-1h10zM5.503 4.5a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5z"
                    fill="currentColor"
                    id="prefix__hotels"
                    stroke="none"
                    stroke-width="1"
                    fill-rule="evenodd"
                ></path>
            </svg>
        )
    }
}

export const UserIcon = ({ selected }: Props) => {
    if (selected) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path fill="#FFF" d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                <path
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2h16z"
                />
                <path
                    fill="#FFF"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 11a4 4 0 100-8 4 4 0 000 8z"
                />
            </svg>
        )
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z"
                />
            </svg>
        )
    }
}

export const OfferIcon = ({ selected }: Props) => {
    if (selected) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                    fill="#FFF"
                    stroke="#F26D2A"
                    d="M11.345 2.569a1 1 0 011.31 0l1.152.998a1 1 0 00.845.227l1.496-.29a1 1 0 011.135.656l.498 1.44a1 1 0 00.619.62l1.44.497a1 1 0 01.655 1.135l-.289 1.496a1 1 0 00.227.845l.998 1.152a1 1 0 010 1.31l-.998 1.152a1 1 0 00-.227.845l.29 1.496a1 1 0 01-.656 1.135l-1.44.498a1 1 0 00-.62.619l-.497 1.44a1 1 0 01-1.135.655l-1.496-.289a1 1 0 00-.845.227l-1.152.998a1 1 0 01-1.31 0l-1.152-.998a1 1 0 00-.845-.227l-1.496.29a1 1 0 01-1.135-.656l-.498-1.44a1 1 0 00-.619-.62l-1.44-.497a1 1 0 01-.656-1.135l.29-1.496a1 1 0 00-.227-.845l-.998-1.152a1 1 0 010-1.31l.998-1.152a1 1 0 00.227-.845l-.29-1.496a1 1 0 01.656-1.135l1.44-.498a1 1 0 00.62-.619l.497-1.44a1 1 0 011.135-.656l1.496.29a1 1 0 00.845-.227l1.152-.998z"
                />
                <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M16 8l-8 8" />
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.048 9.162h.028M15.048 15.162h.028"
                />
            </svg>
        )
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                    stroke="gray"
                    d="M11.345 2.569a1 1 0 011.31 0l1.152.998a1 1 0 00.845.227l1.496-.29a1 1 0 011.135.656l.498 1.44a1 1 0 00.619.62l1.44.497a1 1 0 01.655 1.135l-.289 1.496a1 1 0 00.227.845l.998 1.152a1 1 0 010 1.31l-.998 1.152a1 1 0 00-.227.845l.29 1.496a1 1 0 01-.656 1.135l-1.44.498a1 1 0 00-.62.619l-.497 1.44a1 1 0 01-1.135.655l-1.496-.289a1 1 0 00-.845.227l-1.152.998a1 1 0 01-1.31 0l-1.152-.998a1 1 0 00-.845-.227l-1.496.29a1 1 0 01-1.135-.656l-.498-1.44a1 1 0 00-.619-.62l-1.44-.497a1 1 0 01-.656-1.135l.29-1.496a1 1 0 00-.227-.845l-.998-1.152a1 1 0 010-1.31l.998-1.152a1 1 0 00.227-.845l-.29-1.496a1 1 0 01.656-1.135l1.44-.498a1 1 0 00.62-.619l.497-1.44a1 1 0 011.135-.656l1.496.29a1 1 0 00.845-.227l1.152-.998z"
                />
                <path stroke="gray" strokeLinecap="round" strokeLinejoin="round" d="M16 8l-8 8" />
                <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.048 9.162h.028M15.048 15.162h.028"
                />
            </svg>
        )
    }
}

export const MyAccountNotification = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g>
                    <g transform="translate(4)">
                        <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity="0.5"
                            d="M16 23v-2a3.997 3.997 0 00-4-4H4a3.998 3.998 0 00-4 4v2M8 13a4 4 0 100-8 4 4 0 000 8z"
                        ></path>
                        <rect width="6" height="6" x="13" y="0" fill="#F26D2A" rx="3"></rect>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const WalletLogo = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 5.125H16C16 4.84886 15.7761 4.625 15.5 4.625V5.125ZM15.5 8.875V9.375C15.7761 9.375 16 9.15114 16 8.875H15.5ZM11.75 6.5C11.4739 6.5 11.25 6.72386 11.25 7C11.25 7.27614 11.4739 7.5 11.75 7.5V6.5ZM11.7575 7.5C12.0336 7.5 12.2575 7.27614 12.2575 7C12.2575 6.72386 12.0336 6.5 11.7575 6.5V7.5ZM2 1.5H14V0.5H2V1.5ZM15 2.5V11.5H16V2.5H15ZM14 12.5H2V13.5H14V12.5ZM1 11.5V2.5H0V11.5H1ZM2 12.5C1.44772 12.5 1 12.0523 1 11.5H0C0 12.6046 0.895431 13.5 2 13.5V12.5ZM15 11.5C15 12.0523 14.5523 12.5 14 12.5V13.5C15.1046 13.5 16 12.6046 16 11.5H15ZM14 1.5C14.5523 1.5 15 1.94772 15 2.5H16C16 1.39543 15.1046 0.5 14 0.5V1.5ZM2 0.5C0.895431 0.5 0 1.39543 0 2.5H1C1 1.94772 1.44772 1.5 2 1.5V0.5ZM11.375 5.625H15.5V4.625H11.375V5.625ZM15 5.125V8.875H16V5.125H15ZM15.5 8.375H11.375V9.375H15.5V8.375ZM11.375 8.375C10.6156 8.375 10 7.75939 10 7H9C9 8.31168 10.0633 9.375 11.375 9.375V8.375ZM11.375 4.625C10.0633 4.625 9 5.68832 9 7H10C10 6.24061 10.6156 5.625 11.375 5.625V4.625ZM11.75 7.5H11.7575V6.5H11.75V7.5Z"
                fill="#1A1A1A"
            />
        </svg>
    )
}
