import { Box, Container, Divider, Shimmer } from '@ct-design/lib'
// import DateTabs from '@components/Datebar/DateTabsNew'
import HomePageSummary from '@components/HomePageSummary'
import NewCouponBarWrapper from '@components/NewCouponBarWrapper'
import { useAppSelector } from '@redux/hooks'
import { getMerchandisingResponseData } from '@redux/manager/merchandisingManager'
import { getQueryObj } from '@redux/manager/ui'
import { isEmpty } from '@utils/genralUtils'
import { useEffect, useState } from 'react'
import { getAbConfigData } from '@redux/manager/abManager'

const HomepageSearchLoader = ({ setShowHomeToSRPLoader }) => {
    const summaryData = useAppSelector(getQueryObj)
    const abConfig = useAppSelector(getAbConfigData)
    const [searchQuery, setSearchQuery] = useState({})

    useEffect(() => {
        setSearchQuery({
            departDate: summaryData.depart
        })
    }, [summaryData])

    const MerchandisingLoader = () => {
        return (
            <div>
                <div
                    style={{
                        width: '100vw',
                        paddingLeft: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        marginTop: '8px'
                    }}
                    className="bg-white mb-8 ws-nowrap flex-1"
                >
                    {/* <Stencil width="275" height="39" shimmer={true} className="d-b br-4 ml-6" />
                <Stencil width="275" height="39" shimmer={true} className="d-b br-4 ml-6" /> */}
                    <div style={{ marginLeft: '6px', display: 'block' }}>
                        <Shimmer width={280} height={48} borderRadius={6} />
                    </div>
                    <div style={{ marginLeft: '6px', display: 'block' }}>
                        <Shimmer width={280} height={48} borderRadius={6} />
                    </div>
                </div>
            </div>
        )
    }

    const FiltersChipLoader = () => {
        return (
            <div>
                <div
                    style={{
                        height: '62px',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        gap: '12px'
                    }}
                >
                    {/* <Stencil width="83" height="30" shimmer={true} className="d-b br-pill ml-4" /> */}
                    <div style={{ display: 'block' }}>
                        <Shimmer width={81} height={40} borderRadius={9999} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={87} height={40} borderRadius={9999} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={97} height={40} borderRadius={9999} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={81} height={40} borderRadius={9999} />
                    </div>
                    {/* <div style={{ display: 'block', marginLeft: '4px' }}>
                        <Shimmer width={78} height={30} borderRadius={9999} />
                    </div> */}
                </div>
            </div>
        )
    }

    const DomOWLoader = () => {
        const loaderArr = []
        for (let i = 0; i < 15; i++) {
            loaderArr.push(
                <div key={i} className="br-6-imp mb-8" style={{ border: 'solid 1px #E7E7E7' }}>
                    <Container
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 16px',
                            height: '64px'
                        }}
                    >
                        <Shimmer width={27} height={23} />
                        <Container style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'block' }}>
                                <Shimmer width={128} height={11} />
                            </div>

                            <div style={{ display: 'block' }}>
                                <Shimmer width={58} height={9} />
                            </div>
                        </Container>
                        <Shimmer width={68} height={12} />
                    </Container>
                </div>
            )
        }

        return <div>{loaderArr}</div>
    }

    const DomRTLoader = () => {
        const loaderArr = []
        for (let i = 0; i < 15; i++) {
            loaderArr.push(
                <div key={i} style={{ padding: '0 16px' }}>
                    <Container
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '175px',
                            height: '64px'
                        }}
                    >
                        <Shimmer width={27} height={23} />
                        <Container style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'block' }}>
                                <Shimmer width={128} height={11} />
                            </div>

                            <div style={{ display: 'block' }}>
                                <Shimmer width={58} height={9} />
                            </div>
                        </Container>
                    </Container>
                    <Divider />
                </div>
            )
        }
        return <div>{loaderArr}</div>
    }

    const IntlOWLoader = () => {
        const loaderArr = []
        for (let i = 0; i < 15; i++) {
            loaderArr.push(
                <Container className="pb-2">
                    <div style={{ margin: '8px 16px 0px 16px' }}>
                        <Container
                            className="br-12 p-3"
                            style={{
                                background: `${'#FFFFFF'}`,
                                border: `${'1px solid #E6E6E6'}`
                            }}
                        >
                            <Container style={{ display: 'flex', flexDirection: 'column', padding: '12px' }}>
                                <Container
                                    className="flex flex-middle"
                                    style={{
                                        paddingBottom: '14px'
                                    }}
                                >
                                    <Shimmer width={16} height={16} />

                                    <span
                                        className="fw-500 fs-2 pl-1"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: '500',
                                            color: '#1A1A1A'
                                        }}
                                    >
                                        <Shimmer width={80} height={14.5} />
                                    </span>
                                </Container>
                                <Container className="flex flex-middle pb-1 flex-between">
                                    <span>
                                        <span
                                            className="fw-500"
                                            style={{
                                                color: '#808080',
                                                lineHeight: '16px'
                                            }}
                                        >
                                            <Shimmer width={76} height={16} />
                                        </span>
                                    </span>
                                </Container>
                                <Container
                                    className="flex flex-middle flex-between"
                                    style={{
                                        paddingBottom: '2px',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Container
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <Shimmer width={130} height={24} />
                                    </Container>

                                    <span
                                        className="fw-600"
                                        style={{
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            color: '#1A1A1A'
                                        }}
                                    >
                                        <Shimmer width={63} height={24} />
                                    </span>
                                </Container>
                                <Container className="flex flex-middle flex-between pb-2">
                                    <Container className="flex flex-middle">
                                        <span
                                            className="fw-500"
                                            style={{
                                                lineHeight: '26px',
                                                color: '#1A1A1A'
                                            }}
                                        >
                                            <Shimmer width={230} height={26} />
                                        </span>
                                    </Container>
                                </Container>

                                <Container
                                    className="flex flex-end"
                                    style={{
                                        paddingBottom: '8.5px'
                                    }}
                                >
                                    <span
                                        className="fw-500 "
                                        style={{
                                            lineHeight: '16px',
                                            color: '#11A670'
                                        }}
                                    >
                                        <Shimmer width={200} height={16} />
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        paddingTop: '8.5px',
                                        position: 'relative',
                                        borderTop: ' dashed #DBDBDB',
                                        borderWidth: '1px'
                                    }}
                                >
                                    <Container
                                        className="flex flex-between"
                                        style={{
                                            gap: '8px'
                                        }}
                                    >
                                        <Shimmer width={120} height={22} />

                                        <Shimmer width={20} height={20} />
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    </div>
                </Container>
            )
        }
        return <div>{loaderArr}</div>
    }

    const FlightItemRT = () => {
        return (
            <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', marginBottom: '12px' }}>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={16} height={16} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={53} height={16} />
                    </div>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={76} height={16} />
                    </div>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px' }}>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={104} height={24} />
                    </div>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={48} height={14.5} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <Shimmer width={145} height={16.5} />
                    </div>
                </Box>
            </Box>
        )
    }

    const IntlRTLoader = () => {
        return (
            <div style={{ margin: '0 16px' }}>
                <Box style={{ backgroundColor: 'white' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderStyle: 'solid',
                            borderColor: '#e7e7e7',
                            borderRadius: '12px',
                            padding: '12px',
                            marginTop: '8px'
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderBottom: 'dashed #E6E6E6',
                                borderWidth: '1px'
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '65%',
                                    borderRight: 'dashed #E6E6E6',
                                    borderWidth: '1px',
                                    marginTop: '-12px',
                                    paddingTop: '12px'
                                }}
                            >
                                <FlightItemRT />
                                <div style={{ borderTop: 'dashed #E6E6E6', borderWidth: '1px', marginBottom: '8px' }} />
                                <FlightItemRT />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    width: '35%',
                                    justifyContent: 'center',
                                    alignItems: 'end'
                                }}
                            >
                                <div style={{ display: 'block' }}>
                                    <Shimmer width={65} height={19.5} />
                                </div>
                                <div style={{ display: 'block' }}>
                                    <Shimmer width={90} height={43.5} />
                                </div>
                            </Box>
                        </Box>
                        <Box>
                            <Container
                                style={{
                                    paddingTop: '8.5px',
                                    position: 'relative'
                                }}
                            >
                                <Container
                                    style={{
                                        display: 'flex',
                                        gap: '8px',
                                        overflow: 'scroll'
                                    }}
                                >
                                    <div style={{ display: 'block' }}>
                                        <Shimmer width={102} height={22} />
                                    </div>
                                    <Container>
                                        <Container style={{ width: '48px', height: '1px' }}>&nbsp;</Container>
                                    </Container>
                                </Container>
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: '0px',
                                        top: '8.5px',
                                        width: '59px',
                                        height: '22px',
                                        background:
                                            'linear-gradient(270deg, #FFFFFF 69.44%, rgba(255, 255, 255, 0) 99.02%)',
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <div style={{ display: 'block' }}>
                                        <Shimmer width={20} height={20} />
                                    </div>
                                </div>
                            </Container>
                        </Box>
                    </Box>
                </Box>
            </div>
        )
    }
    let merchandisingData = useAppSelector(getMerchandisingResponseData)
    // console.log(merchandisingData)
    return (
        <div className="HomePageSearchLoader flex flex-column">
            <HomePageSummary setShowHomeToSRPLoader={setShowHomeToSRPLoader} />
            {isEmpty(merchandisingData) ? (
                <MerchandisingLoader />
            ) : (
                // <MerchandisingLoader />
                <NewCouponBarWrapper merchandisingData={merchandisingData} />
            )}

            <FiltersChipLoader />

            <div style={{ marginTop: '4px' }}>
                {summaryData?.tripType === 'oneway' ? (
                    abConfig.newTupple === 'b' ? (
                        <IntlOWLoader />
                    ) : (
                        <div className="px-4">
                            <DomOWLoader />
                        </div>
                    )
                ) : (
                    <div>
                        {summaryData?.isIntl ? (
                            <div>
                                <IntlRTLoader /> <IntlRTLoader /> <IntlRTLoader />
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div style={{ borderRight: '1px solid #eaeaea' }}>
                                    <DomRTLoader />
                                </div>
                                <DomRTLoader />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HomepageSearchLoader
