import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { Truncate } from '@lib/Text'
import { pathType } from '@ui/constants/types'
import { goBack } from '@ui/utils/history'

import Edit from '@ui/lib/Subheader/icons/edit.svg'
import NewBack from '@ui/lib/Subheader/icons/arrow_back.svg'
import ReturnTrip from '@ui/lib/Subheader/icons/swap_horiz.svg'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getQueryObj, setHomePageSearch } from '@redux/manager/ui'
import dayjs from 'dayjs'
import '../../../styles/pwaLib/components/_SummaryHeader.scss'
import { buildVariant } from '@root/configs/Whitelabel'
import { editIconColor } from '@utils/designSystem'

const HomePageSummary = ({ setShowHomeToSRPLoader }) => {
    const summaryData = useAppSelector(getQueryObj)
    const dispatch = useAppDispatch()
    const router = useRouter()

    const newContentClassList = classNames('NewSummaryHeader__content')
    const newSubtitleClass = classNames('fs-12 fw-400  lh-16')
    const orignCode = summaryData.origin.code
    const orign = summaryData.origin.airport?.split(',')[0]

    const destinationCode = summaryData.destination.code
    const destination = summaryData.destination.airport?.split(',')[0]
    const travellers = summaryData.travellers
    const travellerCount = travellers.adults + travellers.children + travellers.infants
    const subtitle = `${dayjs(summaryData.depart).format('DD MMM')} ${
        summaryData.tripType !== 'oneway' ? ' - ' + dayjs(summaryData.returnOn).format('DD MMM') : ''
    } • ${travellerCount} ${travellerCount > 1 ? 'travellers' : 'traveller'} • ${summaryData.fClass}`
    return (
        <>
            <section
                style={{
                    height: '56px',
                    paddingLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    borderBottom: '1px solid #f7f7f7'
                }}
            >
                <div style={{ paddingRight: '16px', display: 'flex', alignItems: 'center' }}>
                    <a
                        onClick={() => {
                            setShowHomeToSRPLoader(false)
                            router.replace({ pathname: '/flights' })
                        }}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <NewBack />
                    </a>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', width: '100%' }}>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="fw-600 fs-14 lh-20 c-black-80" style={{ fontWeight: '600',fontSize:'14px'}}> {orignCode}</p>

                            {orign ? (
                                <div
                                    style={{
                                        marginLeft: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    }}
                                >
                                    <Truncate width={orign.length > 10 ? 80 : ''}>{orign}</Truncate>
                                </div>
                            ) : (
                                ''
                            )}

                            <div style={{ marginLeft: '8px', marginRight: '8px', marginTop: '2px' }}>
                                {!summaryData.returnDate ? '→' : <ReturnTrip />}
                            </div>

                            <p className="fw-600 fs-14 lh-20 c-black-80" style={{ fontWeight: '600',fontSize:'14px'}}>{destinationCode}</p>

                            {destination ? (
                                <div
                                    style={{
                                        marginLeft: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    }}
                                >
                                    <Truncate width={destination.length > 10 ? 80 : ''}>{destination}</Truncate>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>

                        <div style={{ color: '#00000066', width: '255px', marginTop: '4px' }}>
                            <Truncate className={newSubtitleClass}>{subtitle}</Truncate>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                        <Edit stroke={editIconColor[buildVariant]} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomePageSummary
