import React, { FunctionComponent, useState, memo } from 'react'
import { useRouter } from 'next/router'
import { isUserSignedIn } from 'src/utils/genralUtils'
import {
    NAVBAR_ACC_VAL,
    NAVBAR_HOME_VAL,
    NAVBAR_OFFER_VAL,
    NAVBAR_HOTELS_VAL,
    navItemsList
} from 'src/constants/flights'

import { HomeIcon, HotelsIcon, UserIcon, OfferIcon } from 'src/assests/svg/navIcons'
import { onPageStart, appendQuery } from '@utils/appAgent'
import { isEmpty } from '@utils/genralUtils'

interface NaveBarProps {
    selected?: number
}

const NavBar: FunctionComponent<NaveBarProps> = props => {
    const router = useRouter()
    const { selected } = props
    const [selectedIndex, setSelectedIndex] = useState(selected)
    const history = useRouter()

    const handleSelectItem = (index: number, value: string) => {
        const queryParams = router.query
        let routingObj = {
            pathname: '/my-account',
            query: appendQuery(queryParams)
        }

        setSelectedIndex(index)

        switch (value) {
            case NAVBAR_ACC_VAL: {
                Object.assign(routingObj, {
                    pathname: '/my-account'
                })
                break
            }

            case NAVBAR_HOME_VAL: {
                Object.assign(routingObj, {
                    pathname: '/'
                })
                break
            }

            case NAVBAR_HOTELS_VAL: {
                Object.assign(routingObj, {
                    pathname: '/hotels'
                })
                break
            }

            case NAVBAR_OFFER_VAL: {
                onPageStart('FLIGHTS_HOME')
                Object.assign(routingObj, {
                    pathname: '/offers/uae'
                })

                break
            }
        }

        history.push(routingObj)
    }

    const getIcon = (icon: string, selected: boolean) => {
        switch (icon) {
            case 'hotels':
                return <HotelsIcon selected={selected} />
            case 'offer':
                return <OfferIcon selected={selected} />
            case 'myAccount':
                return <UserIcon selected={selected} />
            case 'home':
                return <HomeIcon selected={selected} />
            default:
                return <HomeIcon selected={selected} />
        }
    }

    return (
        <div className="NavBarTabs">
            <div className="NavBarTabs__nav">
                {navItemsList.map((item, index) => (
                    <div className="NavBarTabs__item" key={index} onClick={() => handleSelectItem(index, item.value)}>
                        <div>{getIcon(item.value, selectedIndex === index)}</div>
                        <p className={selectedIndex === index ? 'c-white' : 'c-white-10'}>{item.label}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

NavBar.defaultProps = {
    selected: 0
}
export default memo(NavBar)
