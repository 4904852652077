import React from 'react'
import { Container } from '@ct-design/lib'
import Stencil from '@lib/Stencil'

const CouponBarLoader = () => {
    return (
        <Container className="oldPage">
            <Container
                style={{ width: '100vw' }}
                className="bg-white flex flex-middle pl-16 overflow-x-scroll py-2 ws-nowrap flex-1"
            >
                <Stencil width="275" height="56" shimmer={true} className="d-b br-4 ml-6" />
                <Stencil width="275" height="56" shimmer={true} className="d-b br-4 ml-6" />
            </Container>
        </Container>
    )
}

export default CouponBarLoader
