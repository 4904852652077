// @ts-nocheck
import React, { useCallback, useEffect, useState, memo, useRef } from 'react'
import dynamic from 'next/dynamic'
import { v4 as uuidv4 } from 'uuid'
import { isEmpty, isAirHomePage, path, logAuthCookieInfo, getCookie, invokeCustomEvent } from '@ui/utils'
// import BannerGridContainer from './BannerGridContainer'
import SearchContainer from '@components/SearchContainer'
// import { DEFAULT_IMAGE_URL } from '@root/ui/utils/common'
// import StickyExtraHeight from 'Lib/Exp/Sticky/StickyExtraHeight'
// import Sticky from '@lib/Sticky'
import Cvpwidget from '@components/cvpWidget'

import { isUserSignedIn, getCurrencyByLocale, dispatchNewRelic, safeGetObject } from '@utils/genralUtils'
import {
    getFloaterData,
    getBranchLinkForAppPush,
    getFkSuperCoinsBurnBalance,
    getUserProfileDetails,
    getWalletInfo
} from '@api/homePage'
import Analytics from '@root/ui/utils/Analytics'
import { checkPlatform, dataLayerGA } from '@utils/datalayer'
import { appendQuery, checkDeviceType, getDevicePlatform, isApp, isMsiteInAppWebview, isServer } from '@utils/appAgent'
import { sendClevertapUIActionEvent as CleverTapEvent } from '@utils/loginClevertap'

import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { fetchAbTestData } from '@redux/manager/abManager'
// import CookieBanner from '@components/CookieBanner'
// import ComplianceBanner from '@components/ComplianceBanner'
import { Container } from '@ct-design/lib'
import { isMsiteInFkAppWebView } from '@utils/Ultra'

// const AppPushBottomSheetWrapper = dynamic(() => import(/* webpackChunkName: "AppPushBottomSheet" */ '@components/AppPushBottomSheetWrapper'), {
//     ssr: false,
//     loading: () => <></>
// })

import Footer from '@components/Footer'
import { apiPutOrPostHelper } from '@utils/helper'
import { ClearTripLogo } from '@assests/svg'
import { isFkEnv } from '@root/configs/Whitelabel'
import { handleFlipkartNavigation } from '@utils/Ultra/navigation'
import NavBar from '@components/navBar'
import HomepageSearchLoader from '@components/HomePageSearchLoader'
import { getResultData, setResulResponse } from '@redux/manager/resultManager'
import OfferBlock from '@components/OfferBanner'

// const ComplianceBanner = dynamic(() => import(/* webpackChunkName: "common-Home" */ '@components/ComplianceBanner'), {
//     ssr: false,
//     loading: () => <></>
// })
const CookieBanner = dynamic(() => import(/* webpackChunkName: "common-Home" */ '@components/CookieBanner'), {
    ssr: false,
    loading: () => <></>
})
const Toast = dynamic(() => import(/* webpackChunkName: "common-Home" */ '@lib/Toast'), {
    ssr: false,
    loading: () => <></>
})

function HomePageContainer(props) {
    const { error, bannerList, query, page, widthValue, localeObject } = props || {}
    const DELAY_TO_SHOW_FLOATER = 5000
    const dispatch = useAppDispatch()
    const [showHomeToSRPLoader, setShowHomeToSRPLoader] = useState(false)
    const [showFloater, setShowFloater] = useState(false)
    const [vertical, verticalSelected] = useState('flight')
    const [floaterData, setFloaterData] = useState({
        elements: null,
        campaignId: null
    })
    const [signInsuccess, setSignInsuccess] = useState(false)
    const [fkSupercoinBurnBalance, setfkSupercoinBurnBalance] = useState('')
    // const loginModuleFallbackUri = isServer() ? '/' : window?.location?.pathname?.replace('/', '')
    const [isLoginOpen, setIsLoginOpen] = useState(false)
    const [locationData, setLocationData] = useState({})
    const [showToast, setShowToast] = useState({
        isVisible: false
    })
    const [queryObj, setQueryForm] = useState(props.fsHistoryMobile)
    //app push
    const [appPushABResult, setAppPushABResult] = useState(null)
    const [appPushButtonLoading, setAppPushButtonLoading] = useState(false)
    const [showAppPushBottomSheet, setShowAppPushBottomSheet] = useState(
        !isServer() && window?.sessionStorage?.getItem('isAppPushBannerShown') ? false : true
    )
    const [showAppPushStickyBanner, setShowAppPushStickyBanner] = useState(false)
    const [appPushBenefitsCardData, setAppPushBenefitsCardData] = useState({})
    const [benefitsAPIResponse, setBenefitsAPIResponse] = useState(null)
    const [walletBalance, setWalletBalance] = useState('')
    const [appPushWalletBalance, setAppPushWalletBalance] = useState(null)
    const [waitingForWalletData, setWaitingForWalletData] = useState(true)
    const [walletLoading, setWalletLoading] = useState(true)
    const [branchLinkAPIFailure, setBranchLinkAPIFailure] = useState(false)
    const [gratificationMsg, setGratificationMsg] = useState('')
    const [showGratificationMsg, setShowGratificationMsg] = useState(false)
    const resultsData = useAppSelector(getResultData)

    const firstLoad = useRef(true)

    let timer = null
    let queryParams = isApp(query)
    const [hasAppBannerLoaded, setHasAppBannerLoaded] = useState(false)

    const initialPageLoadTime = useRef(0)

    const showLoginPopup = () => {
        setIsLoginOpen(true)
        CleverTapEvent({
            actionName: 'home_page_login_clicked',
            action: 'signin',
            is_logged_in: 'no',
            page: 'flight_home',
            platform: checkPlatform()
        })
        dataLayerGA(
            {
                action_name: 'user_initiated_login',
                is_logged_in: 'no',
                vertical: 'air',
                page_name: 'flights_home'
            },
            'login_clicked'
        )
    }
    // const handleLoginClose = () => {
    //     setIsLoginOpen(false)

    //     let popupDismiss = false
    //     const obj = JSON.parse(
    //         path(['sessionStorage'], window) &&
    //         typeof window.sessionStorage !== 'undefined' &&
    //         window.sessionStorage.getItem('loginPopupPwa')
    //     )

    //     if (window.location.pathname.includes('itinerary') && obj?.flightIternary) {
    //         popupDismiss = true
    //     } else if (
    //         (window.location.pathname.includes('/') || window.location.pathname.includes('flights')) &&
    //         obj?.flightHome
    //     ) {
    //         popupDismiss = true
    //     }

    //     CleverTapEvent({
    //         actionName: 'Login_modal_dismissed',
    //         actionType: 'Button',
    //         auto_pop: popupDismiss,
    //         page: 'flight_search'
    //     })
    //     dataLayerGA({ section: 'Login' }, 'login_skip_clicked')

    //     ravenSDKTrigger('u_login_click', {
    //         action_name: 'login_prompt_dismissed',
    //         login_form: 'skippable_login',
    //         login_type: 'native',
    //         vertical: 'air',
    //         page_name: 'a_home'
    //     })
    // }

    // const handleLoginSuccess = async () => {
    //     setSignInsuccess(true)
    //     invokeCustomEvent('CLEVERTAP_PROFILE',{ type: 'login'})
    //     // setShowToast({
    //     //     isVisible: true,
    //     //     message: 'Logged in successfully!'
    //     // })
    // }
    // const handleLoginFailure = () => {
    //     setShowToast({
    //         isVisible: true,
    //         message: 'Something went wrong. Please try again.'
    //     })
    // }

    const onPageShow = () => {
        if (showHomeToSRPLoader) {
            setShowHomeToSRPLoader(false)
        }
        if (!isEmpty(resultsData)) {
            dispatch(setResulResponse({}))
        }
    }

    useEffect(() => {
        window.addEventListener('pageshow', onPageShow)
        onPageShow()
        dispatchNewRelic({
            action_name: 'Air_PageLoad',
            payload: {
                page_name: 'flights_home'
            }
        })
        return () => {
            window.addEventListener('pageshow', onPageShow)
        }
    }, [])
    useEffect(() => {
        setTimer()
        return function cleanup() {
            clearTimeout(timer)
        }
        //eslint-disable-next-line
    }, [showToast.isVisible])
    const setTimer = () => {
        // clear any existing timer
        if (timer != null) clearTimeout(timer)

        // hide after delay
        timer = setTimeout(() => {
            hideToast()
            timer = null
        }, 2500)
    }
    const hideToast = () => {
        setShowToast({
            isVisible: false,
            message: ''
        })
    }
    const isMobileApp = queryParams && queryParams.isMobileApp === 'true'
    const appBanner =
        !isServer() &&
        path(['sessionStorage'], window) &&
        typeof window.sessionStorage !== 'undefined' &&
        window.sessionStorage.getItem('mobileAppBanner')
    const IS_USER_SIGNED_IN = isUserSignedIn() ? 'yes' : 'no'
    const PLATFORM = checkPlatform()
    const floaterWidgetLoadFailureEvent = () => {
        let payload = {
            action_name: 'hp_floater_widget_load_failure',
            platform: PLATFORM,
            is_logged_in: IS_USER_SIGNED_IN,
            type_of_failure: 'widget'
        }
        Analytics.event('merch_floater_events', payload)
    }
    const fetchFloaterDataAndSetState = async () => {
        try {
            const floaterResponse = await getFloaterData({
                slotIds: ['air_pwa_home_page']
            })
            const floaterHomePageData = floaterResponse.find(item => item?.slotId === 'air_pwa_home_page')
            if (floaterHomePageData?.campaignId && floaterHomePageData?.elements) {
                setShowFloater(true)
                const floaterElementData = JSON.parse(floaterHomePageData.elements)
                setFloaterData(prev => ({
                    ...prev,
                    campaignId: floaterHomePageData?.campaignId,
                    elements: floaterElementData
                }))
            }
        } catch (e) {
            floaterWidgetLoadFailureEvent()
        }
    }

    const fsHistorySanitizer = fsHistory => {
        const alphabetsAndSpaceRegex = /^[a-zA-Z\s]+$/
        const alphabetsAndUnderscoreRegex = /^[a-zA-Z_]+$/
        const travellerRegex = /^[0-9]+$/
        const destinationSanity = /^[a-zA-Z,\s\-]+$/

        if (isEmpty(fsHistory)) return {}
        const fsHistoryClone = JSON.parse(JSON.stringify(fsHistory))
        const {
            destinationHeader,
            sourceHeader,
            departDate,
            returnDate,
            travellers,
            flightClass,
            sft,
            tripType,
            source,
            destination
        } = fsHistoryClone

        if (!destinationSanity.test(destinationHeader)) {
            fsHistoryClone.destinationHeader = ''
            fsHistoryClone.destination = ''
        }
        if (!destinationSanity.test(sourceHeader)) {
            fsHistoryClone.sourceHeader = ''
            fsHistoryClone.source = ''
        }
        let departDateCheck = departDate.split('/')
        let returnDateCheck = returnDate?.split('/')
        departDateCheck = new Date(`${departDateCheck?.[1]}/${departDateCheck?.[0]}/${departDateCheck?.[2]}`)
        returnDateCheck = new Date(`${returnDateCheck?.[1]}/${returnDateCheck?.[0]}/${returnDateCheck?.[2]}`)
        const isValidDepartDate = !isNaN(departDateCheck.getTime())
        const isValidReturnDate = !isNaN(returnDateCheck.getTime())
        if (!isValidDepartDate) {
            fsHistoryClone.departDate = ''
        }
        if (!isValidReturnDate) {
            fsHistoryClone.returnDate = ''
        }
        if (
            !(
                travellerRegex.test(travellers.adults) &&
                travellerRegex.test(travellers.children) &&
                travellerRegex.test(travellers.infants)
            )
        ) {
            fsHistoryClone.travellers = { adults: 1, children: 0, infants: 0 }
        }
        if (!alphabetsAndSpaceRegex.test(flightClass)) {
            fsHistoryClone.flightClass = 'Economy'
        }
        if (!isEmpty(sft) && !alphabetsAndUnderscoreRegex.test(sft)) {
            fsHistoryClone.sft = ''
        }
        if (!alphabetsAndSpaceRegex.test(tripType)) {
            fsHistoryClone.tripType = 'oneway'
        }
        return fsHistoryClone
    }

    useEffect(() => {
        initialPageLoadTime.current = new Date().getTime()
        // if (!isEmpty(props.abTest) && !hasEffectRun) {
        Analytics.event('cleartrip home viewed', {
            platform: 'm-web-pwa',
            page_name: 'flights_home'
        })
        const payload = {
            // 'user_status':'{{dynamic}}',
            section: 'Flights'
        }
        if (
            (isMobileApp && getDevicePlatform() === 'iOS') ||
            (typeof window.sessionStorage != 'undefined' && sessionStorage.getItem('mobileApp') === 'iPhoneApp')
        ) {
            setTimeout(() => {
                dataLayerGA(payload, 'home_page_land')
            }, 5000)
        } else {
            dataLayerGA(payload, 'home_page_land')
        }

        //ravenSDKTrigger('a_home_pageload', ravenPayLoadNew)
        // if (typeof localStorage != 'undefined' && localStorage.getItem('fsHistoryMobile')) {
        //     const fsHistory = fsHistorySanitizer(JSON.parse(localStorage.getItem('fsHistoryMobile')))
        //     setQueryForm(fsHistory)
        // }
        if (
            typeof window !== 'undefined' &&
            typeof window?.sessionStorage !== 'undefined' &&
            window?.sessionStorage?.getItem('savedFilters')
        ) {
            window?.sessionStorage?.removeItem('savedFilters')
        }
        const pageLoadTime = parseInt(new Date().getTime() - initialPageLoadTime.current)

        dispatch(fetchAbTestData())
    }, [])

    useEffect(() => {
        if (firstLoad.current) {
            ; (async () => {
                let payload = []
                let sourceIndex = -1
                let destonationIndex = -1
                if (queryObj.source) {
                    payload.push(queryObj.source)
                    sourceIndex = 0;
                }
                if (queryObj.destination) {
                    payload.push(queryObj.destination)
                    destonationIndex = sourceIndex + 1
                }
                let URL = `/places/airports/search/v3`
                const response = await apiPutOrPostHelper(
                    URL,
                    payload,
                    'application/json'
                )
                if (response.status === 200) {
                    const { code, data } = response.data
                    if (code === 200) {
                        try {
                            setQueryForm({
                                ...queryObj,
                                sourceHeader: data[sourceIndex]?.airportCity || '',
                                destinationHeader: data[destonationIndex]?.airportCity || ''

                            })
                            setLocationData({
                                origin: data[0],
                                destination: data[1]
                            })
                        }
                        catch (e) {

                        }
                    }
                }
            })()
            firstLoad.current = false
        }
    }, [queryObj])

    // useEffect(() => {
    //     if((!isUserSignedIn() && appPushABResult === CONTROL_SET)||(!isUserSignedIn() && isMsiteInAppWebview())){
    //         skippableOverlay()
    //     }
    // }, [appPushABResult])

    // const fetchWalletInfoAndTransactions = async () => {
    //     try {
    //         const startTime = new Date().getTime()
    //         const userIdCookie = getCookie('userid')
    //         const userId = decodeURIComponent(userIdCookie)?.split('|')[4]
    //         const utmCurr = getCookie('utm_currency')
    //         const defaultCurObj = getCurrencyByLocale()
    //         let defaultCurrency = utmCurr?.split('|')[0] || defaultCurObj?.currency || ''

    //         //make parallel api calls:
    //         Promise.allSettled([getWalletInfo(userId, defaultCurrency), getUserProfileDetails()])
    //             .then(results => {
    //                 const [walletResult, userProfileResult] = results || []

    //                 if (walletResult?.status === 'fulfilled') {
    //                     const walletRes = walletResult.value
    //                     const { status: walletResStatus, data: walletData = {} } = walletRes || {}

    //                     if (walletResStatus === 200) {
    //                         const elapsedTime = new Date().getTime() - startTime

    //                         const { balance = 0, expiryDetails: expiry_details } = walletData || {}
    //                         setWalletBalance(balance)
    //                         setWalletLoading(false)
    //                         if (elapsedTime < CUTOFF_TO_SHOW_APP_PUSH) {
    //                             const rewardWalletBalance = walletData?.walletList?.REWARD?.balance || null
    //                             setAppPushWalletBalance(rewardWalletBalance)
    //                         }
    //                         waitingForWalletData && setWaitingForWalletData(false)
    //                     } else {
    //                         // setWalletLoading(false);
    //                         setWalletBalance('')
    //                         waitingForWalletData && setWaitingForWalletData(false)
    //                     }
    //                 }
    //             })
    //             .catch(err => {
    //                 setWalletLoading(false)
    //                 waitingForWalletData && setWaitingForWalletData(false)
    //                 console.log('promise error: ', err)
    //             })
    //     } catch (err) {
    //         waitingForWalletData && setWaitingForWalletData(false)
    //         console.error('fetchWalletInfoAndTransactions err: ', err)
    //     }
    // }

    // useEffect(() => {
    //     let timer = null
    //     if (isUserSignedIn() || signInsuccess) {
    //         fetchWalletInfoAndTransactions()
    //     } else {
    //         setWaitingForWalletData(false)
    //     }
    //     return () => clearTimeout(timer)
    // }, [signInsuccess])

    const skippableOverlay = () => {
        if (!isUserSignedIn()) {
            const obj = JSON.parse(
                path(['sessionStorage'], window) &&
                typeof window.sessionStorage !== 'undefined' &&
                window.sessionStorage.getItem('loginPopupPwa')
            )

            if (!obj?.flightHome && window.location.pathname == '/') {
                setTimeout(() => {
                    if (isMsiteInAppWebview() || appPushABResult === CONTROL_SET) {
                        setIsLoginOpen(true)
                        window.sessionStorage.setItem(
                            'loginPopupPwa',
                            JSON.stringify({ ...obj, flightHome: 'true', flightIternary: 'true' })
                        )
                    }
                })

                /* logging cookie info after 3 seconds for ios apps if they were shown the login popup */
                setTimeout(() => {
                    logAuthCookieInfo('home-page-retry', 0)
                }, 3000)
            }
        }
    }

    //fire the floater fetch call again if the user signs in or is signed in by default
    // useEffect(() => {
    //     let timer = null
    //     if (isAirHomePage() && appPushABResult!==null && appPushABResult!==APP_PUSH) {
    //         timer = setTimeout(fetchFloaterDataAndSetState, DELAY_TO_SHOW_FLOATER);
    //     }
    //     return () => clearTimeout(timer)
    // }, [signInsuccess, appPushABResult])

    //fire the floater fetch call again if the user is not signed in
    // useEffect(() => {
    //     if (isAirHomePage() && !isUserSignedIn() && appPushABResult!==null && appPushABResult!==APP_PUSH) {
    //         skippableOverlay()
    //     }
    // }, [appPushABResult])

    // useEffect(() => {
    //     let timer
    //     if (branchLinkAPIFailure) {
    //         timer = setTimeout(() => {
    //             setBranchLinkAPIFailure(false)
    //         }, 3000)
    //     }
    //     return () => {
    //         timer && clearTimeout(timer)
    //     }
    // }, [branchLinkAPIFailure])

    // useEffect(() => {
    //     let timer
    //     if (showGratificationMsg) {
    //         timer = setTimeout(() => {
    //             setShowGratificationMsg(false)
    //             const attributes = JSON.parse(window.sessionStorage.getItem('appPushAttributes'))
    //             delete attributes.gratificationMsg
    //             window.sessionStorage.setItem('appPushAttributes', JSON.stringify(attributes))
    //         }, 3000)
    //     }
    //     return () => {
    //         timer && clearTimeout(timer)
    //     }
    // }, [showGratificationMsg])

    const handleAppPushBannerClick = () => {
        setShowAppPushBottomSheet(true)
    }
    //removing floater component from DOM
    const destroyFloater = () => {
        setShowFloater(false)
    }
    const onAppBannerLoad = useCallback(() => {
        setHasAppBannerLoaded(true)
    }, [])
    const triggerClevertap = ({ actionName, additionalData = {} }) => {
        CleverTapEvent({
            actionName,
            additionalData
        })
    }
    const { carousal, banners } = bannerList || {}
    let carousalData = safeGetObject(carousal)
    let bannersData = safeGetObject(banners)

    return (
        <>
            {showHomeToSRPLoader ? (
                <HomepageSearchLoader setShowHomeToSRPLoader={setShowHomeToSRPLoader} />
            ) : (
                <>
                    {/* <CtBanner campaignTopic='ct_flights_pwa_home' isMobileApp={isMsiteInAppWebview()} onClick={handleBannerClick} onLoad={handleBannerOnLoad}/> */}

                    <div className="homePage">
                        <div className="px-4 flex py-16 flex-center flex-middle">
                            <ClearTripLogo color="#1a1a1a" />
                        </div>

                        <SearchContainer
                            imgArray={[]}
                            closeFromSRP={() => { }}
                            query={queryObj}
                            abTest={{}}
                            locationData={locationData}
                            setShowHomeToSRPLoader={setShowHomeToSRPLoader}
                        />

                        {/* <div style={{ height: '100%', width: '100%' }} className="p-relative">
                    {!error && !isEmpty(bannerList) && (
                        <BannerGridContainer bannerList={bannerList} widthValue={widthValue} />
                    )}
                </div> */}

                        {!isEmpty(bannersData) &&
                            <div style={{ height: "180px" }}>
                                <div className='px-4 py-4'>
                                    <OfferBlock Data={bannersData} />

                                </div>
                            </div>}

                        {!isEmpty(carousalData) &&
                            <div style={{ paddingTop: "45px" }}>
                                <Cvpwidget offerData={carousalData} />
                            </div>}
                        {!isMobileApp && <Footer locale={localeObject?.locale} page={page} />}


                        <Toast
                            message="Somethings went wrong, we’re checking it"
                            isVisible={branchLinkAPIFailure}
                            showCloseButton={false}
                            messageType="error"
                            containerClass="toast--appPush"
                            messageClass=""
                        />
                        <Toast
                            message={gratificationMsg}
                            isVisible={showGratificationMsg}
                            showCloseButton={false}
                            messageType="success"
                            containerClass="toast--appPush"
                            messageClass=""
                        />
                    </div>

                    {/* {showFloater && <Floater floaterData={floaterData} onDestroy={destroyFloater} />} */}

                    <div className="p-fixed b-0 w-100p ">
                        <NavBar />
                    </div>
                    {/* We cannot conditionally render the LoginModule component because
                if the component is unmounted, it may lead to issues with sending events
                that are tightly coupled inside it post-login. Additionally, the FK SSO flow
                will be interrupted as well. */}
                    {/* <LoginModule
                    isDrawerOpen={isLoginOpen}
                    handleLoginClose={handleLoginClose}
                    fallbackUri={loginModuleFallbackUri}
                    onAuthSuccess={handleLoginSuccess}
                    onAuthFailure={handleLoginFailure}
                    skipProfileFlow={false}
                /> */}

                    {!isMsiteInAppWebview() && <CookieBanner />}

                    <Toast isVisible={showToast.isVisible} message={showToast.message} />
                </>
            )}
        </>
    )
}
export default memo(HomePageContainer)
