import React from 'react'

function Icon() {
    return (
        <svg width="16" height="16" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.50017 17.6153C2.0399 17.6153 1.6556 17.4611 1.34727 17.1528C1.03893 16.8444 0.884766 16.4601 0.884766 15.9999V5.11523C0.884766 4.97293 0.932524 4.85403 1.02804 4.75851C1.12356 4.66299 1.24247 4.61523 1.38477 4.61523C1.52708 4.61523 1.646 4.66299 1.74152 4.75851C1.83702 4.85403 1.88477 4.97293 1.88477 5.11523V15.9999C1.88477 16.1537 1.94887 16.2947 2.07709 16.4229C2.20529 16.5511 2.34632 16.6152 2.50017 16.6152H10.3848C10.5271 16.6152 10.646 16.663 10.7415 16.7585C10.837 16.854 10.8848 16.9729 10.8848 17.1153C10.8848 17.2576 10.837 17.3765 10.7415 17.472C10.646 17.5675 10.5271 17.6153 10.3848 17.6153H2.50017ZM5.50017 14.6152C5.0399 14.6152 4.6556 14.4611 4.34727 14.1527C4.03893 13.8444 3.88477 13.4601 3.88477 12.9999V2.23061C3.88477 1.77036 4.03893 1.38607 4.34727 1.07773C4.6556 0.769401 5.0399 0.615234 5.50017 0.615234H13.2694C13.7297 0.615234 14.114 0.769401 14.4223 1.07773C14.7306 1.38607 14.8848 1.77036 14.8848 2.23061V12.9999C14.8848 13.4601 14.7306 13.8444 14.4223 14.1527C14.114 14.4611 13.7297 14.6152 13.2694 14.6152H5.50017ZM5.50017 13.6152H13.2694C13.4232 13.6152 13.5643 13.5511 13.6925 13.4229C13.8207 13.2947 13.8848 13.1537 13.8848 12.9999V2.23061C13.8848 2.07678 13.8207 1.93575 13.6925 1.80753C13.5643 1.67933 13.4232 1.61523 13.2694 1.61523H5.50017C5.34632 1.61523 5.20529 1.67933 5.07709 1.80753C4.94889 1.93575 4.88479 2.07678 4.88479 2.23061V12.9999C4.88479 13.1537 4.94889 13.2947 5.07709 13.4229C5.20529 13.5511 5.34632 13.6152 5.50017 13.6152Z"
                fill="#fff"
            />
        </svg>
    )
}

export default Icon
