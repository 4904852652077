import { MouseEvent } from 'react'
import { Container, Typography } from "@ct-design/lib"
import ContentCopyIcon from '@components/CouponTag/ContentCopyIcon'

interface HotelCouponTagProps {
    couponCode: string
    couponIcon: JSX.Element
    copy: boolean
    onCopy: (arg1: MouseEvent<HTMLButtonElement>) => void
}

const HotelCouponTag = ({
    couponCode = "",
    couponIcon = <></>,
    copy = false,
    onCopy = () => { }
}: HotelCouponTagProps) => {
    return (
        <div>
            <Container
                backgroundColor="#f66826;"
                className="p-2 flex br-8 flex flex-middle p-relative"
                width="fit-content"
                style={{ padding: "7px", minWidth: "110px" }}
            >
                <span
                    style={{
                        position: 'absolute',
                        background: '#ECF3FF',
                        height: '4px',
                        width: '8px',
                        borderRadius: ' 0 0 6px 6px',
                        top: '0',
                        left: '36px'
                    }}
                ></span>
                {couponIcon}
                <span
                    style={{
                        position: 'absolute',
                        background: '#ECF3FF',
                        height: '4px',
                        width: '8px',
                        borderRadius: ' 6px 6px 0 0',
                        bottom: '0',
                        left: '36px'
                    }}
                ></span>
                <Typography
                    color="neutral"
                    variant={"HM3"}
                    className="pl-5"
                    dataTestId="coupon_code"
                >
                    {couponCode || ''}
                </Typography>
                {copy && (
                    <Container
                        className="ml-3 flex flex-center"
                        marginRight="2px"
                        onClick={onCopy}
                    >
                        <ContentCopyIcon />
                    </Container>
                )}
            </Container>
        </div>
    )
}

export default HotelCouponTag