// Content's for all the domains of flight page
const aeContent = () => {
    return (
        <div className="bg-white pb-10">
            <p className="fs-14 lh-copy pt-10 c-black-60">
                You can find the ideal flight that fits your travel needs with the help of Cleartrip.ae's
                convenient online booking, affordable prices, and extensive airline selection.
            </p>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                When it comes to seamless and stress- free vacation planning, nobody beats Cleartrip.ae.Our
                cheap flight tickets, user - friendly interface, special bargains, and flexible options
                (including free rescheduling and hassle - free cancellations) make us your best pick.
            </p>
            < ul className="pt-2" >
                <li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Easy &amp; Quick Booking </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Through our user - friendly website, Cleartrip.ae enables fast and easy flight booking,
                    helping travelers to find their cheap flights with minimal time and effort spent.Take
                    advantage of our low rates, helpful customer service, and optimized booking process to
                    organize your trip without a hitch.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Book with flexibility(Flexifly)
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Get the most out of your travel plans with Cleartrip's Flexifly feature. It lets you
                    reschedule flights, so you can book with peace of mind and adapt to ever - changing plans.
                    Experience the freedom to effortlessly amend your flight tickets and pay only the cost
                    difference, if any, for smooth and easy travel.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Lowest Price </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    With a large range of airlines offering reasonable tickets and exclusive deals,
                    Cleartrip.ae is the best place to go for budget - friendly flight tickets and economical
                    airline travel.We aim to offer the lowest prices on cheap flights, ensuring travelers
                    can secure the best deals and savings for their journeys.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Exciting Offers &amp; Deals
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    With exclusive offers, promotions, and discounts, Cleartrip.ae offers various amazing
                    airline bargains and deals for travelers to book flight tickets and enjoy their journey.
                    Whether through flash sales or seasonal discounts, travelers can maximize their savings
                    and stretch their budgets when purchasing cheap flights online.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > 24 / 7 Support </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Cleartrip.ae ensures 24 - hour support services to support travelers with any inquiries or
                    emergencies.Feel free to contact our customer service staff at any time, day or night,
                    for any questions, modifications to your flight tickets, or assistance you may need
                    throughout your trip.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Travel Insurance </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Travel Guard by Cleartrip will provide you with complete protection against any
                    unanticipated incidents, providing you with peace of mind during your travels.The
                    insurance has you covered for last - minute cancellations, unexpected medical conditions,
                    or early return.Enroll with Travel Guard as you book flight tickets to safeguard your
                    journey against any unanticipated disruptions that may occur while you are away.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Easy Cancellation(EzCancel)
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    When you book with EzCancel from Cleartrip your itinerary plans are protected from
                    last - minute changes.Even with non - refundable tickets, passengers have the option to
                    cancel their flights up to 10 hours before departure and receive 90 % of their money
                    back.You can rest easy and save money on cancellation costs with EzCancel since any
                    flight tickets booked within the first 90 days of membership qualify.
                </p>
            </ul>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                Book Cheapest Flights & Save More on Your Flight Booking.
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Book the cheapest flights with Cleartrip.ae and save more on your flight booking by taking
                advantage of our exclusive deals and offers.Experience an intuitive reservation process and
                find the most economical rates for your travel requirements with us.
            </p>

            < ul className="pt-2" >
                <li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Domestic Flight Booking: </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    When you book your domestic ticket through Cleartrip.ae, not only is it quick and easy,
                    but you also have access to a variety of airlines offering the cheapest flight tickets.
                    Take advantage of our comprehensive travel assistance and simple booking process while
                    you marvel at the beauty of the UAE.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h4 className="fs-16 lh-title fw-600 c-neutral-900 pt-10" >
                        International Flight Booking:
                    </h4>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Easy and convenient International ticket purchase is made possible by Cleartrip.ae,
                    which offers a broad selection of airlines from around the world, tickets at reasonable
                    prices, with exciting special discounts.Get ready for your foreign vacation with
                    complete peace of mind with comprehensive assistance, flexible rescheduling choices, and
                    a simple cancellation approach to a clear trip.
                </p>
                <div align="left">
                    <table
                        style={{
                            margin: '4px 0',
                            border: 'none',
                            borderCollapse: 'collapse'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-cochin-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Kochi flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-mumbai-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Mumbai flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-new-delhi-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to New Delhi flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/sharjah-trivandrum-flights.html"
                                        >
                                            <u>
                                                <span>Sharjah to Trivandrum flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-hyderabad-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Hyderabad flights </span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-calicut-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Kozhikode flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/abu-dhabi-cochin-flights.html"
                                        >
                                            <u>
                                                <span>Abu Dhabi to Kochi flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-chennai-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Chennai flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/mumbai-dubai-flights.html"
                                        >
                                            <u>
                                                <span>Mumbai to Dubai flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ul>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                How to Search and Book Cheap Flight Tickets on Cleartrip.ae ?
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                On Cleartrip.ae, you can find the cheapest airline tickets by entering your travel
                information and then browsing through the various available options for your best choice.To
                make the most out of your savings, take advantage of our exclusive sales and bargains while
                booking flights.Complete the booking process with passenger details and payment information
                for a budget - friendly travel experience.
            </p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                How to Book Flexible Flights with Changeable Dates on Cleartrip.ae ?
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Cleartrip has various options that allow you to book your cheap flight ticket for flexible
                dates, for a reasonable fee, you can find them during the booking process.Complete the
                process for booking flights as usual, ensuring peace of mind with the level of flexibility
                needed to adjust your travel plans as needed.
            </p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                Check Exciting Flight Offers on Cleartrip.ae for Budget - Friendly Travel
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                We believe in helping you choose the best deals for your flights, as a result, our exclusive
                deals, sales, and offers are highlighted on the checkout page after you have determined your
                flight.Our intuitive interface makes it simple to compare prices, choose full itineraries,
                and accept changes or cancellations after purchasing the cheapest flight tickets so that
                your trip planning is easy and economical.
            </p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >Our Top Airlines</h2>
            <ul>
                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Air Arabia</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        <a href="https://www.cleartrip.ae/flight-booking/air-arabia-airlines.html">
                            Air Arabia&nbsp;
                        </a>
                        is a low-cost airline headquartered in Sharjah, UAE that connects major
                        cities in the Middle East, Asia, Africa, and Europe. They are particularly
                        known for their affordable rates, reliability, and efficient customer
                        service. Their growing fleet and network make them a popular choice for
                        travellers.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Air India Express</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        Being one of the top low-cost international airlines in India,&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/air-india-express-airlines.html">
                            Air India Express&nbsp;
                        </a>
                        offers economical travel while providing smooth access to the Middle East
                        and Southeast Asia. With years of experience in budget-friendly travel,
                        the carrier is one of the leaders in low-cost international travel. This airline is
                        known for its low-cost and effective business, both for leisure and business purposes.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Flydubai</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        <a href="https://www.cleartrip.ae/flight-booking/flydubai-airlines.html">
                            Flydubai&nbsp;
                        </a>
                        is an innovative low-fare airline based in Dubai that serves the Middle
                        East, Africa, Europe, and Asia. It operates a modern fleet of aircraft and
                        provides affordable tickets, earning a positive reputation. The airline gives
                        comfortable flying with easy connections through Dubai to many parts of the world.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Wizz Air</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        As one of the ultra-low-cost carriers expanding rapidly in Europe,&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/wizz-air-airlines.html">
                            Wizz Air&nbsp;
                        </a>
                        provides economical travel opportunities with low-cost flights within
                        Europe and the Middle East. Wizz Air offers cheap, fast, and comfortable
                        travel without the extras. With a modern fleet, competitive pricing, and efficient operations,
                        it's known for providing a no-frills and reliable flying experience for all.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >IndiGo</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        India’s biggest and preferred airline,&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/indigo-airlines.html">
                            IndiGo&nbsp;
                        </a>
                        has established its name in the airline industry for being very
                        cost-efficient and offering a wide range of national and global flights.
                        The airline is the top choice for travellers because of its timely flights at reasonable prices.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Etihad Airways</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        Etihad Airways is an airline that specializes in innovation and luxury air travel.
                        With its state-of-the-art fleet, Etihad services travelers in a unique way while connecting major
                        cities all over the world. This has made&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/etihad-airways-airlines.html">
                            Etihad Airways&nbsp;
                        </a> a global leader in premium airline service. The carrier is also renowned for its extensive global
                        network that spans across six continents.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Air India</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        <a href="https://www.cleartrip.ae/flight-booking/air-india-airlines.html">
                            Air India&nbsp;
                        </a>
                        is the premier airline in India and serves its customers with a mix of
                        modernity and heritage while connecting various important locations all
                        over the globe. With full service and premium features, it provides great
                        service to all its passengers and is an airline well-known around the world.
                        The carrier is a much-preferred carrier for those who value comfort, as well
                        as reliability.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Emirates</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        Flying to over 150 destinations around the world,&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/emirates-airlines.html">
                            Emirates&nbsp;
                        </a>
                        is one of the bestselling and awarded airlines renowned for its in-flight
                        service and hospitality.  As a global leader in the aviation industry, it
                        features a modern fleet of aircraft with world-class amenities and in-flight
                        multi-national entertainment. For all its features, customers have come to
                        expect an unparalleled travel experience from the airline.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >Qatar Airways</h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        Highly praised for its luxurious service,&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/qatar-airways-airlines.html">
                            Qatar Airways&nbsp;
                        </a>
                        has made its mark in the world as one of the leading airlines, delivering
                        unmatched value and excellence to customers around the world. Together with the
                        modern fleet and a focus on comfort, the airline has an extensive global network,
                        allowing passengers to redefine long-distance travel. With all their top facilities,
                        it is no wonder that Qatar is consistently recognized as one of the world’s top airlines.
                    </p>
                </li>

                <li className="c-neutral-700 fs-3 pt-4">
                    <h3 className="fs-15 fw-600 c-neutral-900 pt-10" >
                        Pakistan International Airlines
                    </h3>
                    <p className="fs-14 lh-copy pt-10 c-black-60">
                        As a government-operated airline,&nbsp;
                        <a href="https://www.cleartrip.ae/flight-booking/pakistan-international-airlines.html">
                            Pakistan International Airlines&nbsp;
                        </a>
                        (PIA) is connected to many domestic and international routes, providing
                        services at a competent price. As a flagship carrier of the country, PIA has
                        assisted travelers over the years by providing easy links to various locations in Asia,
                        the Middle East, Europe, and North America.
                    </p>
                </li>
            </ul>
        </div>
    )
}

const qaContent = () => {
    return (
        <div className="bg-white pb-10" >
            <p className="fs-14 lh-copy pt-10 c-black-60" >
                The effortless online booking, fair rates, and wide airline options of Cleartrip.qa helps
                you to select the ideal flight for your travel needs.Cleartrip.qa is the top choice for
                convenient and stress - free vacation planning.Our travel option is top - notch with affordable
                flight tickets, a user - friendly interface, great discounts, and flexible choices like free
                rescheduling and easy cancellations, making us the ideal choice...
            </p>
            < ul className="pt-2" >
                <li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Easy &amp; Quick Booking Process
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Affordable flight searching is now an easy task with Cleartrip.qa and our intuitive
                    website.Using our affordable rates, courteous customer service, and simplified booking
                    system will help you to easily make your reservation.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Flexible Booking Options(FlexiFly)
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    With Flexifly, you can change flight tickets with minimum restrictions and only pay the
                    difference.You do not have to worry if you have changed your plans after making
                    vacation reservations as the Flexifly feature will assist you in promptly and
                    inexpensively changing flight itineraries.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Lowest Price Guarantee </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    When seeking low - cost air tickets or airline vacation packages, the best option for
                    flight tickets is Cleartrip.qa which is one of the few options with reasonably priced
                    flight reservation services and air vacation packages.We have low pricing on tickets
                    which enables the customers to spend less on journeys and make the best out of the
                    affordable tickets.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Exciting Offers &amp; Deals
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Use Cleartrip.qa to book low - cost airline tickets and enjoy some holiday time away!
                    Other than the promotional offerings, travellers can also save some money by buying
                    cheap flights to their destinations during ‘flash sales’ or ‘off - season’ sales.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > 24 / 7 Customer Support </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Cleartrip.qa 24 / 7 customer care comes in handy for people who wish to go to Qatar and
                    are in need of assistance.For any inquiries, reservation changes, or cancellation
                    issues that may come about during the time while on a trip, our support assistant is
                    available around the clock in the customer support service to offer assistance.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Comprehensive Travel Insurance
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    When you have Travel Guard from Cleartrip, you don’t have to worry about anything
                    because you are assured that your travel is covered.It takes care of early returns,
                    medical problems that were not anticipated as well as cancellations that happen at the
                    last minute.Relax when booking flights because Travel Guard has policies covering
                    events that can arise during a journey you have undertaken.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                        Easy Cancellation(EzCancel)
                    </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Using EzCancel from Cleartrip protects your well - considered travel plans from
                    unanticipated events.Even with non - refundable tickets, passengers can get 90 % of their
                    money back if they cancel their inexpensive flights up to 10 hours before departure.
                    Since every flight ticket booking booked during the initial ninety days of subscription
                    qualifies, EzCancel helps you rest and save money on cancellation charges.
                </p>
            </ul>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                <strong>Discover Qatar and Beyond with Cleartrip.qa </strong>
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Cleartrip.qa is your trustworthy travel partner for booking flight tickets online and
                once -in -a - lifetime experiences that help you experience the beautiful country of Qatar and
                more.
            </p>
            < p className="fs-14 lh-copy pt-10 c-black-60" > - Explore Qatar’s Attractions.</p>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Consider visiting the inspiring &amp; imposing modern skyscrapers as well as the ancient
                desert fortresses and other remarkable places in Qatar.If however, you are in need of more
                leisure or adventure, then make your choice from the plenty of fascinating sites in Qatar,
                both ancient and modern.
            </p>
            < p className="fs-14 lh-copy pt-10 c-black-60" > - Travel Beyond Qatar </p>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Experience the stunning landmarks and cultures of the neighbouring Gulf countries like Oman,
                UAE, Saudi Arabia, etc.Enjoy the stunning desert countryside and the population of
                metropolis cities like of Dubai or Riyadh or Abu Dhabi and so forth.Venture to countless
                destinations that are just a short flight away from Qatar any time you desire.
            </p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                <strong>
                    Book Affordable Flights &amp; Save More on Your Flight Booking with Cleartrip.qa
                </strong>
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Discover how to get smarter deals on the cheapest flight tickets when booking flights
                through Cleartrip.qa.With our cheap flight tickets, promotional offers, and deals, you will
                be able to get the lowest flight rates possible.Book for the best prices and rates of
                flights that are best suitable for your travel needs with us by using our simple and fast
                online booking system.
            </p>
            < ul className="pt-2" >
                <li className="c-neutral-700 fs-3 pt-4" >
                    <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Domestic Flight Booking: </h3>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    You can easily and quickly book cheap domestic flights through Cleartrip.qa, which
                    connects you to many companies offering the best deals.With our simple booking process
                    and comprehensive travel assistance, you can fully appreciate the stunning landscapes of
                    Qatar.
                </p>
                < li className="c-neutral-700 fs-3 pt-4" >
                    <h4 className="fs-16 lh-title fw-600 c-neutral-900 pt-10" >
                        International Flight Booking:
                    </h4>
                </li>
                < p className="fs-14 lh-copy pt-10 c-black-60" >
                    Booking a flight ticket online for international travel is a piece of cake with
                    Cleartrip.qa.Our flights go to every corner of the world and we always have very low
                    prices along with great deals and discounts.Cleartrip’s detailed service, simple
                    cancelling process as well as a variety of rescheduling options, will let you easily go
                    on that long - needed trip abroad whenever you want.
                </p>
            </ul>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                Top Domestic and International Flight Routes from Qatar
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                There are good domestic airlines that connect the major cities in the country and
                international airlines that serve other major cities of the world such as Mumbai, London,
                and New York.The top - notch airlines are sufficient to ensure all travellers are comfortable
                while moving in and out of Qatar.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Domestic Routes </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Experience the convenience of domestic routes from Qatar, which will connect you to
                important regional destinations with simplicity and comfort.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" >
                International Routes(e.g., Riyadh to Dubai, Jeddah to Cairo)
            </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                {' '}
                With Qatar's great international flight network, you can travel to many major cities
                worldwide and explore a variety of locations.
            </p>
            < div align="left" >
                <table
                    style={
                        {
                            margin: '4px 0',
                            border: 'none',
                            borderCollapse: 'collapse'
                        }
                    }
                >
                    <tbody>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/dubai-calicut-flights.html"
                                    >
                                        <u>
                                            <span>Dubai to Calicut Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/trivandrum-sharjah-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Sharjah Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/abu-dhabi-cochin-flights.html"
                                    >
                                        <u>
                                            <span>Abu Dhabi to Kochi Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                        < tr >
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/trivandrum-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Dubai Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/sharjah-varanasi-flights.html"
                                    >
                                        <u>
                                            <span>Sharjah to Varanasi Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/calicut-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Calicut to Dubai Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                How to Find and Book Cheap Flight Tickets on Cleartrip.qa ?
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Finding and making reservations for cheap travel tickets is made easy and fast by the price
                notifications, flexible date options, and the search filters of Cleartrip.qa.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Utilize Advanced Search Filters </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Cleartrip.qa allows you to refine your search to the cheapest flights, in terms of cost,
                time, and carrier.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Monitor Price Alerts </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Cut down costs on your preferred routes, by setting fare notifications to alert you if the
                prices happen to get lower than what you expected.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Book Early for the Best Deals </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Make reservations on tickets way before the high season so that you can be charged lower.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Use Flexible Date Search </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Cleartrip.qa’s flexible date search capability makes it possible to check for the
                lowest - priced flight bookings over certain periods.
            </p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                Flexible Flight Booking Options on Cleartrip.qa ?
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                When making a flight reservation through cleartrip, it provides you several options for
                payment which will enable you to cheaply book the flight while accommodating other
                preferences and time changes.
            </p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >
                Explore Exciting Flight Deals &amp; Offers on Cleartrip.qa
            </h2>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Find the best flight costs with special promotions and offers at checkout.You may compare
                prices, choose itineraries, and make changes or cancellations with ease using our
                user - friendly website.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Seasonal promotions </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Enjoy special airline savings and holiday discounts during celebratory seasons with
                Cleartrip.qa.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Last - minute deals </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Take advantage of last - minute flight tickets to save a ton of money on those impromptu
                vacations.
            </p>
            < h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > Special offers for members </h3>
            < p className="fs-14 lh-copy pt-10 c-black-60" >
                Use special member - only offers on Cleartrip.qa to unlock further advantages and discounts.
            </p>

        </div>)
}

const bhContent = () => {
    return (
        <div className="bg-white pb-10" >
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh guarantees a hassle-free ticketing procedure and also helps customers to find the right airline at low rates The website is fantastic for vacation planning and free of any tension with additional benefits like easy rescheduling and cancellations along with plenty of unique offers and discounts.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Easy &amp; Quick Booking</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Customers need not worry about the cost of budgeting for their trip because they can search for expensive flights with clear trips.&nbsp;Bh on its efficient website. Enjoy the low pricing and fast flight booking process by relying on our efficient resellers for those quick travel needs.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Book with flexibility (Flexifly)</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >FlexiFly offers a unique approach that allows you to reschedule your flight ticket, by only paying the fare difference, if there is any. When you use this feature, your vacation plans become efficient and cheap since you can change the travel schedule.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Lowest Price</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh offers low airfare and vacation packages, making it one of the best sites for booking travel and lodging for tight-budget travellers. Utilise our competitively priced tickets and vacation packages to save on your trip and enable yourself to enjoy more with your travel budget.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Exciting Offers &amp; Deals</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Grab low-cost flight deals through Cleartrip.&nbsp;Bh. Our platform also regularly involves flash sales and off-season offers that enable travellers to book cheap flights to great destinations.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong> 24/7 Support</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >At Cleartrip.&nbsp;Bh, friendly personnel are available all day and every day to handle any questions, modifications or reservations if there are any during the journey. Our customer support department is functioning 24 hours 7 days a week for our clients so that their travels are always pleasant.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Travel Insurance</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >All Cleartrip’s customers can fully enjoy their trips when they take the Travel Guard which protects them from impromptu cancellations, emergencies, and returning early without any losses. Travel Guard ensures your journey is protected, allowing you to book flight tickets worry-free.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Easy Cancellation (EzCancel)</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Ezcancel lets you shield your journey from any eventualities that are unforeseen. You can cancel flight tickets up to ten hours before flight departure and get back 90% of the&nbsp;ticket value on non-refundable tickets, without incurring any cancellation charges, EzCancel helps you secure your peace of mind through cost savings.</p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Explore Bahrain: Plan Your Perfect Trip with Cleartrip.&nbsp;bh</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh is your reliable travel partner for seamless flight ticket booking online and creating unforgettable experiences in Bahrain and beyond.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Explore Bahrain’s Attractions</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Bahrain's historic and unique places of interests as well as its history is revealed through its many renowned features, and exhibitions, and styles of construction.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Bahrain National Museum</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >At this famous Bahrain National Museum, travellers stand a chance to learn about the country’s heritage and traditions through different eras by viewing many collections of prehistorical and historical importance as well as modern-day art.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Bahrain Fort (Qal'at al-Bahrain)</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Recognised by Unesco as a World Heritage site this ancient Persian Fort will provide beautiful vantage points of the present Bahraini peninsula and a peek into 4000 years of history in Bahrain.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Al-Fateh Grand Mosque</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Beautiful in its construction, it is the world’s fourth largest mosque and is well known for its peaceful atmosphere while helping its visitors appreciate the culture and faith.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Bab Al Bahrain</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >This area acts as a historical gateway to the Manama Souq allowing travellers to shop for local crafts, spices, and presents.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>The Avenues, Bahrain</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >A tourist’s favourite place to shop and relax by the sea, offering a variety of shops, restaurants and entertainment, as well as the&nbsp;picturesque scenery of Bahrain Bay.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Travel Beyond Bahrain</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Travel to Gulf countries near Bahrain such as Saudi Arabia, the UAE and Qatar, where various cultures, contemporary cities, and beautiful natural surroundings await, all within a short distance from the island nation.</p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" ><strong>Save Big on Flight Bookings with Cleartrip.&nbsp;bh</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh is great for finding smart deals and cheap flights as it helps you to browse through numerous offers or book the best ticket available for your travel needs.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Domestic Flight Booking:</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh is very useful in booking dirt cheap domestic flight tickets from several domestic airlines offering the lowest flight booking deals. Booking accommodative and hassle-free travel around Bahrain has never been so easy with the&nbsp;fast-booking process and extensive travel assistance.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > <strong>International Flight Booking:</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Using Cleartrip.&nbsp;Bh to book international flights is as smooth as butter. With a variety of destinations to choose from and ease of changing or cancelling, travellers do not face challenges in travelling around the world.</p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" > <strong>Top Domestic and International Flight Routes from Bahrain</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60" >A major feature of Cleartrip.&nbsp;Bh is the ability to help travellers reach important domestic and foreign directions with comfort and ease, whether the travel is within the country or outside.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Domestic Routes </strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Easy inter-city travel within Bahrain is made easier for you with Cleartrip which connects important cities and regional areas in the kingdom with ease. Airlines such are Gulf Air, Ak Bars Aero, and DHL Aviation Eemea B.S.C are available on the site for domestic flights</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > <strong>International Routes </strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh connects you to a variety of international flights like Gulf Air, British Airways, Emirates, Air Asia, Vaso Airlines, Singapore Airlines, Continental, Darwin Airline and many more, taking you to places such as London, New York, Dubai, etc around the world.</p>
            < div align="left" >
                <table
                    style={
                        {
                            margin: '4px 0',
                            border: 'none',
                            borderCollapse: 'collapse'
                        }
                    }
                >
                    <tbody>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/dubai-calicut-flights.html"
                                    >
                                        <u>
                                            <span>Dubai to Calicut Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/trivandrum-sharjah-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Sharjah Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/abu-dhabi-cochin-flights.html"
                                    >
                                        <u>
                                            <span>Abu Dhabi to Kochi Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                        < tr >
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/trivandrum-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Dubai Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/sharjah-varanasi-flights.html"
                                    >
                                        <u>
                                            <span>Sharjah to Varanasi Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/calicut-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Calicut to Dubai Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" > <strong>How to Find and Book Cheap Flight Tickets on Cleartrip.&nbsp;Bh?</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Through its features like price alerts, flexible booking dates, and advanced search features, Cleartrip.&nbsp;Bh makes cheap flight booking accessible and easy to locate and reserve online.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Utilize Advanced Search Filters</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Use the filters for cost, time, and airline preferences on Cleartrip.&nbsp;Bh to narrow down the options available to you and ensure that you only get the cheapest flights that are available at the moment.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Monitor Price Alerts</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >You can set up fare alerts to notify you when your route’s rates drop. This will enable you to reap maximum benefits and score some of the cheapest flights, especially during off-peak travel seasons.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Book Early for Best Deals</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >A substantial amount of money can be saved if you book your flights several months in advance, especially when there is low demand in off season travelling.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Use Flexible Date Search</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >If you cannot seem to find a good fare, use the simple but useful flexible date search option on Cleartrip.&nbsp;Bh to look for cheaper fares on other dates.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Flexible Flight Booking Options on Cleartrip.&nbsp;bh</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh offers a range of payment options for travellers, making it easy to book your flight while accommodating your preferences and schedule changes.</p>
            < h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" ><strong>How to Get Access to Exciting Flight Deals &amp; Offers on Cleartrip.&nbsp;Bh?</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Cleartrip.&nbsp;bh provides a variety of deals and offers at checkout, helping you find the best prices for your flights and travel plans.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Seasonal promotions</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Make the most out of the festive discounts by booking your low-cost airline with Cleartrip.&nbsp;Bh at special reduced rates.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Last-minute deals</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >Get great captures on last-minute flight tickets, and gear up for impromptu getaways.</p>
            <h3 className="fs-15 lh-title fw-600 c-neutral-900 pt-10" ><strong>Special offers for members</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60" >More discounts and perks are available on the clear trip.&nbsp;Bh with offers exclusive to members.</p>
        </div>
    )
}

const saContent = () => {
    return (
        <div className="bg-white pb-10" >
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.sa's handy online booking, reasonable rates, and large airline choices assist you in choosing&nbsp;the perfect flight for your trip requirements. In terms of easy and hassle-free vacation planning, no one does it better than Cleartrip.sa. Our affordable flight tickets, easy-to-use interface, exceptional discounts, and flexible choices—which include free rescheduling and effortless cancellations—make us your best choice for travel.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Easy &amp; Quick Booking</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Finding inexpensive flights doesn't have to be a hassle anymore thanks to Cleartrip.sa and our user-friendly website. Make your reservation with ease by taking advantage of our cheap prices, friendly customer service, and streamlined booking process.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Book with flexibility (Flexifly)</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Experience the ability to simply change your flight tickets and pay only the cost difference with Flexifly. The Flexifly feature on Cleartrip.sa helps you maximize your vacation arrangements. With the ability to reschedule flights, you can book flights with confidence and easily adjust to any changes in plans. </p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Lowest Price</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">When looking for cheap plane tickets or airline vacations, Cleartrip.sa is your best bet because of the wide variety of airlines that provide affordable flight booking and exclusive discounts. We strive to provide the most affordable flight prices so that travellers may get the greatest bargains and save the most on their trips. </p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Exciting Offers &amp; Deals</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Book your cheap flight tickets and have a wonderful trip with Cleartrip.sa's special deals, promotions, and discounts on a wide range of airlines. Travelers can optimize their savings and extend their budgets by buying cheap flights online using quick sales or seasonal discounts.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>24/7 Support</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Travelers can rely on Cleartrip.sa's round-the-clock support services for any questions or situations. No matter the time of day or night, our customer care representatives are available to help you with flight booking, inquiries, and changes to your tickets, or problems that may arise during your journey.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Travel Insurance</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Travel Guard from Cleartrip will give you total security against any unplanned events, thereby giving you peace of mind while you travel. The insurance covers early returns, unanticipated medical issues, and last-minute cancellations. Book flight tickets with Travel Guard to protect your trip against any unplanned interruptions while you are abroad.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Easy Cancellation (EzCancel)</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Your carefully crafted travel plans are safeguarded from unforeseen changes when you use EzCancel from Cleartrip. Passengers can cancel their cheap flights up to ten hours before departure and get ninety percent&nbsp;of their money back even with non-refundable tickets. EzCancel lets you relax and save funds on cancellation charges since any flight ticket booking purchased within the first ninety days of membership is qualified.</p>

            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Book Cheapest Flights &amp; Save More on Your Flight Booking.</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Discover the unbeatable savings waiting for you when you book your flights with Cleartrip.sa. Take advantage of our cheap flight tickets, exclusive deals, and offers to secure the most economical flights available. Utilize our seamless and user-friendly reservation process and uncover the best deals and unbeatable rates tailored to your specific travel needs, only with us.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Domestic Flight Booking:</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Booking domestic flight tickets through Cleartrip.sa is simple and fast and gives you access to many different airlines that provide the best deals on cheap flights. Enjoy Saudi Arabia's breathtaking scenery with the help of our easy booking process and thorough travel support. Fly with popular airlines like Saudi Arabian Air, Saudi Gulf Airlines, FlyNas Airlines, Emirates, Air India, Turkish Airlines, Flyadeal, and many more.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>International Flight Booking:</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">With Cleartrip.sa, buying international tickets has never been easier. We have flights from around the globe, and our prices are always affordable, coupled with our amazing deals and discounts that will blow your mind. With Cleartrip's thorough service, flexible rescheduling possibilities, and straightforward cancellation method, you can prepare for your overseas trips with total peace of mind. The best international airlines are connected to the major Saudi Arabian cities such as British Airways, Singapore Airlines, Air Arabia, Qatar Airways, Emirates, Air France, United, Lufthansa, and many more.</p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Popular Flight Routes from Saudi Arabia</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Explore Saudi Arabia's beauty and hospitality by traveling through major airline routes in comfort and style. Experience a smooth ride from Riyadh to Jeddah, a busy route for business and leisure. You can travel to Dubai and feel its cosmopolitan vibes. Discover the smooth correlation between Jeddah and Dammam, connecting western and eastern Saudi Arabia. With frequent and convenient cheap flights, your trip to Saudi Arabia will be unforgettable.</p>

            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>International Routes </strong></h3>
            < div align="left" >
                <table
                    style={
                        {
                            margin: '4px 0',
                            border: 'none',
                            borderCollapse: 'collapse'
                        }
                    }
                >
                    <tbody>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/dubai-calicut-flights.html"
                                    >
                                        <u>
                                            <span>Dubai to Calicut Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/trivandrum-sharjah-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Sharjah Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/abu-dhabi-cochin-flights.html"
                                    >
                                        <u>
                                            <span>Abu Dhabi to Kochi Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                        < tr >
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/trivandrum-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Dubai Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/sharjah-varanasi-flights.html"
                                    >
                                        <u>
                                            <span>Sharjah to Varanasi Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            < td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5" >
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/calicut-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Calicut to Dubai Flights </span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>How to Search and Book Cheap Flight Tickets on Cleartrip.sa?</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Simply input your travel details on Cleartrip.sa, and then browse the many available options to obtain the best deal on airline tickets. Make the most of your money by purchasing your cheap flight tickets during one of our special sales events. Fill out the booking form with the names of passengers and their payment details for a trip that won't break the bank. </p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>How to Book Flexible Flights with Changeable Dates on Cleartrip.sa?</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">There are several choices available to you during the flight ticket booking process on Cleartrip that will allow you to purchase your inexpensive flight ticket for flexible dates for a fair charge. Go ahead and make your flight booking as normal, our range of options will provide you the flexibility you need to make last-minute changes to your itinerary without worry.</p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Check Exciting Flight Deals &amp; Offers on Cleartrip.sa</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">When you reach the checkout page after selecting your flight, you'll see our special deals, sales, and offers highlighted, because we believe in assisting you in choosing the greatest deals for your travels. For hassle-free and budget-friendly vacation planning, our user-friendly interface makes it easy to compare costs, select complete itineraries, and accept adjustments or cancellations after buying the best flight tickets.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Seasonal promotions</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Travel becomes more inexpensive all year round with Cleartrip's spectacular seasonal offerings, which offer big discounts on domestic and international flights.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Last-minute deals</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Amazing last-minute flight discounts are available on Cleartrip. You can book a flight at the last minute and still get the best rates with us.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"><strong>Special offers for members</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Premium travel deals, exclusive savings on cheap flights and hotels, and other exclusive perks are available only to Cleartrip members.</p>
        </div>
    )
}

// Faq's for all the domains of flight page
const aeFaq = () => {
    return [
        {
            heading: '1. How to get last-minute flight deals?',
            values: [
                {
                    type: 'text',
                    value: 'Stay up-to-date with the latest flight deals by using the cleartrip. The app, subscribing to email alerts, booking during off-peak hours, and following cleartrip on social media to take advantage of flash promotions. Get even more discounts on cheap flights by using promo codes, credit card deals, Cleartrip Wallet, or getting in touch with customer care.'
                }
            ]
        },
        {
            heading: '2. How to find the best flight deals?',
            values: [
                {
                    type: 'text',
                    value: `You can use Cleartrip's flexible dates feature, create fare alerts, and keep an eye on the "Offers and Deals" section for savings on flight bookings. Save more money by planning, taking layovers into account, using discount coupons, and using the Cleartrip app with bargains and notifications on cheap flights.`
                }
            ]
        },
        {
            heading: '3. How to get flight updates on mobile?',
            values: [
                {
                    type: 'text',
                    value: `You can use the <a href="https://www.cleartrip.ae/mobile/" className='text-blue hover:underline'>Cleartrip app</a>  to get updates on your flight status, you just have to create an account, log in, and enable push notifications. Make use of the app's Booking Details feature to monitor your flight's status and get in touch with customer service if you face any issues.`
                }
            ]
        },
        {
            heading: '4. How to stay updated with the latest flight deals & offers?',
            values: [
                {
                    type: 'text',
                    value: `Check out the Offers Page at <a href="https://www.cleartrip.ae/offers/uae" className='text-blue hover:underline'>Offers Page</a> to get the latest deals`
                }
            ]
        },
        {
            heading: `5. What's the maximum booking window for flights?`,
            values: [
                {
                    type: 'text',
                    value: ` On cleartrip.ae, you can usually book your flight up to 330 days in advance, or up to 11 months in advance, depending on the airline and the kind of aircraft. Please note that this time frame is subject to change according to variables like airline operations and schedule.`
                }
            ]
        }, {
            heading: `6. How to get domestic flight booking offers on Cleartrip.ae? `,
            values: [
                {
                    type: 'text',
                    value: `You can find great bargains on domestic flight ticket bookings by visiting the offers section and using promo codes or credit card offers.`
                }
            ]
        }, {
            heading: `7. How to get international flight booking offers on Cleartrip.ae?`,
            values: [
                {
                    type: 'text',
                    value: `To get affordable prices on international airline bookings, visit the offers section of Cleartrip.ae, use promo codes or check credit card offers.`
                }
            ]
        }, {
            heading: `8. What is the best time to book flights?`,
            values: [
                {
                    type: 'text',
                    value: `The most suitable time to book a flight is during off-peak periods, such as early morning or late night, and is well in advance of peak holiday seasons. If you are looking to save money, try to skip booking flight tickets on weekends, when rates rise due to high demand.`
                }
            ]
        }, {
            heading: `9. What is layover in flights?`,
            values: [
                {
                    type: 'text',
                    value: `Layovers are intermediate airports where air travelers often disembark from their flights to catch a connecting flight to their intended destination.`
                }
            ]
        }, {
            heading: `10. Does opting for international flights with layovers save on airfare?`,
            values: [
                {
                    type: 'text',
                    value: `International layovers are typically less expensive than non-stop flights as airlines may lower fares to fill seats on unflown routes due to market demand and competition. In addition, to entice customers, the airline may offer discounted layover flight tickets via its hub airports.`
                }
            ]
        }, {
            heading: `11. How to cancel flights online on Cleartrip.ae?`,
            values: [
                {
                    type: 'text',
                    value: `Visit  <a href="https://www.cleartrip.ae/faq/account/tour/flights_cancellation.shtml" className='text-blue hover:underline'>Cancellation page</a> to cancel flights on cleartrip.ae`
                }
            ]
        }
    ]

}

const qaFaq = () => {
    return [
        {
            heading: '1. How to get last-minute flight deals?',
            values: [
                {
                    type: 'text',
                    value: 'Stay up-to-date with the latest flight deals by using the cleartrip. The app, subscribing to email alerts, booking during off-peak hours, and following cleartrip on social media to take advantage of flash promotions. Get even more discounts on cheap flights by using promo codes, credit card deals, Cleartrip Wallet, or getting in touch with customer care.'
                }
            ]
        },
        {
            heading: '2. How to find the best flight deals?',
            values: [
                {
                    type: 'text',
                    value: `You can use Cleartrip's flexible dates feature, create fare alerts, and keep an eye on the "Offers and Deals" section for savings on flight bookings. Save more money by planning, taking layovers into account, using discount coupons, and using the Cleartrip app with bargains and notifications on cheap flights.`
                }
            ]
        },
        {
            heading: '3. How to get flight updates on mobile?',
            values: [
                {
                    type: 'text',
                    value: 'To monitor flight status using the Cleartrip app, create a profile, log in, and activate push notifications. You need to use the Booking Details section to find your flight and also call customer support for assistance if necessary.'
                }
            ]
        },
        {
            heading: '4. How to stay updated with the latest flight deals & offers?',
            values: [
                {
                    type: 'text',
                    value: 'If you would like to know more about the latest cleartrip.qa’s offers then please head over to the Offers Page at cleartrip.qa/offers/Qatar.'
                }
            ]
        },
        {
            heading: '5. What\'s the maximum booking window for flights?',
            values: [
                {
                    type: 'text',
                    value: 'You can book flight tickets through Cleartrip.qa for either up to 330 days or 11 months, subject to the carrier and type of aircraft. The period in question may be different due to airline routing and operations.'
                }
            ]
        },
        {
            heading: '6. How to get domestic flight booking offers on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'Explore the deals section and apply promo codes or credit card discounts to uncover great savings on domestic flight bookings.'
                }
            ]
        },
        {
            heading: '7. How to get international flight booking offers on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'Get amazing deals on international flight tickets by comparing options, opting for promo codes, or checking offers on Cleartrip.qa.'
                }
            ]
        },
        {
            heading: '8. What is the best time to book flights?',
            values: [
                {
                    type: 'text',
                    value: 'The flights are normally available at low rates during off-peak times such as early in the morning or very late at night, and they should be booked very much ahead of peak seasons. Do your best to avoid buying tickets during weekends because their increasing demand usually raises costs.'
                }
            ]
        },
        {
            heading: '9. What is layover in flights?',
            values: [
                {
                    type: 'text',
                    value: 'Passengers often leave their flights at airports that act as intermediate stops on longer routes to catch a connecting flight to their final destination; these airports are referred to as layovers.'
                }
            ]
        },
        {
            heading: '10. Does opting for international flights with layovers save on airfare?',
            values: [
                {
                    type: 'text',
                    value: 'International layovers are often cheaper than nonstop flights because airlines may lower prices on less popular routes to meet consumer demand and remain competitive. To attract customers, airlines might also offer discounted online bookings for layover flights through their major airports during these times.'
                }
            ]
        },
        {
            heading: '11. How to cancel flights online on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'You can cancel your flight ticket booking online on Cleartrip.qa by going to your account settings or using the Manage Bookings section.'
                }
            ]
        },
        {
            heading: '12. What are the best deals offered for flight bookings on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'Cleartrip.qa offers a variety of deals for flight bookings, including seasonal promotions, last-minute discounts, and exclusive offers for members. Additionally, users can benefit from promo codes and special sales highlighted at checkout for even greater savings.'
                }
            ]
        }
    ];
}

const bhFaq = () => {
    return [
        {
            "heading": "1. How to get last minute flight deals?",
            "values": [
                {
                    "type": "text",
                    "value": "Follow Cleartrip.bh on their social networks, join the email lists, and avail of the promo codes to gain information about the current deals."
                }
            ]
        },
        {
            "heading": "2. How to find the best flight deals?",
            "values": [
                {
                    "type": "text",
                    "value": "Make use of the flexible dates option, create fare alerts, and check the 'Offers and Deals' section on Cleartrip.bh to save money as much as possible."
                }
            ]
        },
        {
            "heading": "3. How to get flight updates on mobile?",
            "values": [
                {
                    "type": "text",
                    "value": "In order to get flight notifications on the mobile phone, download the Cleartrip application, sign in, and turn on the notifications."
                }
            ]
        },
        {
            "heading": "4. How to stay updated with the latest flight deals & offers?",
            "values": [
                {
                    "type": "text",
                    "value": "You can visit Cleartrip.bh's Offers Page, which is filled with the current offers and discounts available on flights."
                }
            ]
        },
        {
            "heading": "5. What's the maximum booking window for flights?",
            "values": [
                {
                    "type": "text",
                    "value": "The maximum reservation period permitted for booking flights on Cleartrip.bh is 330 days or eleven months before departure for most of the airlines on the site."
                }
            ]
        },
        {
            "heading": "6. How to get domestic flight booking offers on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "To get the best offers, find the deals section, seek promo codes, apply them, and go through your options to pick cheap tickets for domestic flights."
                }
            ]
        },
        {
            "heading": "7. How to get international flight booking offers on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "If looking for international flight ticket booking offers on Cleartrip.bh, then just use the promo codes, view the deals section, and compare your options for extra discounts."
                }
            ]
        },
        {
            "heading": "8. What is the best time to book flights?",
            "values": [
                {
                    "type": "text",
                    "value": "Try to reserve your flights at non-peak times, for instance, in the early hours or during the late hours of the night, and try not to book on weekends as much as possible because the demand for tickets is high at that time."
                }
            ]
        },
        {
            "heading": "9. What is layover in flights?",
            "values": [
                {
                    "type": "text",
                    "value": "A layover is a break or a halt at a specific airport on the way to the ultimate destination on another booked flight."
                }
            ]
        },
        {
            "heading": "10. Does opting for international flights with layovers save on airfare?",
            "values": [
                {
                    "type": "text",
                    "value": "Layover flights typically have lower fares than direct flights, and that helps in cutting down the costs of international travel."
                }
            ]
        },
        {
            "heading": "11. How to cancel flights online on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "Visit the flight cancellation page on Cleartrip.bh to cancel your flights in a short time."
                }
            ]
        },
        {
            "heading": "12. What are the best deals offered for flight bookings on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "Cleartrip.bh offers perks like seasonal promotions, last-minute discounts, member-exclusive offers, and special sales with promo codes for additional savings."
                }
            ]
        }
    ]
}

const saFaq = () => {
    return [
        {
            heading: "1. How to get last-minute flight deals?",
            values: [
                {
                    type: "text",
                    value: "Take the benefit of flash promotions by engaging with Cleartrip on social media, downloading the Cleartrip.sa app, reserving during off-peak hours, subscribing to email notifications, and staying updated on the best flight offers. Save even more money on already low-priced flights by contacting customer service, using promo codes, or taking advantage of credit card specials."
                }
            ]
        },
        {
            heading: "2. How to find the best flight deals?",
            values: [
                {
                    type: "text",
                    value: "To save money on booking flights, use Cleartrip's option for flexible dates, set up fare notifications, and stay tuned to the 'Offers and Deals' area. You can save even more money on cheap flights by being proactive, considering layovers, shopping around, and making use of discount coupons and the Cleartrip app, which offers deals and travel alerts."
                }
            ]
        },
        {
            heading: "3. How to get flight updates on mobile?",
            values: [
                {
                    type: "text",
                    value: "Get flight status updates with the Cleartrip app. All you have to do is establish a profile, sign in, and activate push notifications. To check on the progress of your flight and contact customer support in the event of any problems, utilize the application's Booking Details function."
                }
            ]
        },
        {
            heading: "4. How to stay updated with the latest flight deals & offers?",
            values: [
                {
                    type: "text",
                    value: "For the most up-to-date information on offers on Cleartrip.sa, visit the Offers Page https://www.cleartrip.sa/offers/saudi-arabia."
                }
            ]
        },
        {
            heading: "5. What's the maximum booking window for flights?",
            values: [
                {
                    type: "text",
                    value: "You can typically reserve your flight on Cleartrip.sa for up to 330 days or as much as eleven months ahead, according to the airline and the type of aircraft. This time range could shift depending on factors like airline schedules and operations."
                }
            ]
        },
        {
            heading: "6. How to get domestic flight booking offers on Cleartrip.sa?",
            values: [
                {
                    type: "text",
                    value: "By browsing the deals area and making use of promo codes or credit card discounts, you can discover fantastic deals on domestic airline bookings."
                }
            ]
        },
        {
            heading: "7. How to get international flight booking offers on Cleartrip.sa?",
            values: [
                {
                    type: "text",
                    value: "You can find great deals on foreign airline tickets by shopping around, using promo codes, or checking the offers page of Cleartrip.sa."
                }
            ]
        },
        {
            heading: "8. What is the best time to book flights?",
            values: [
                {
                    type: "text",
                    value: "Booking a flight during off-peak times, such as at dawn or late at night, and far in advance of the busy holiday season is the best time to book your flight tickets. Avoid purchasing plane tickets on weekends if you can help it as the demand drives up prices."
                }
            ]
        },
        {
            heading: "9. What is layover in flights?",
            values: [
                {
                    type: "text",
                    value: "To board a connecting flight for their final destination, passengers often depart from their flights at airports that serve as intermediate stops on a long route; these airports are known as layovers."
                }
            ]
        },
        {
            heading: "10. Does opting for international flights with layovers save on airfare?",
            values: [
                {
                    type: "text",
                    value: "International layovers are usually more affordable than nonstop flights because airlines sometimes reduce costs on underutilized routes because of consumer demand and competitive pricing. Additionally, to attract customers, the airline might provide cheaper flight ticket booking online for layovers through its major airports during such time."
                }
            ]
        },
        {
            heading: "11. How to cancel flights online on Cleartrip.sa?",
            values: [
                {
                    type: "text",
                    value: "Cleartrip.sa allows users to cancel their airline tickets at <a href='https://www.cleartrip.sa/faq/account/tour/flights_cancellation.html'>Cancellation page</a>."
                }
            ]
        }
    ];
}


export const flightPageContents = {
    AE: {
        heading: "Flight Booking Online: Why Choose Cleartrip.ae?",
        faqs: aeFaq(),
        content: aeContent()
    },
    QA: {
        heading: "Why Book Flights with Cleartrip.qa?",
        faqs: qaFaq(),
        content: qaContent()
    },
    BH: {
        heading: "Why Book Flights with Cleartrip. Bh?",
        faqs: bhFaq(),
        content: bhContent()
    },
    SA: {
        heading: "Why Book Flights with Cleartrip.sa?",
        faqs: saFaq(),
        content: saContent()
    }
}