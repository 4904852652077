import React, { useEffect, useState } from 'react'
import CouponBar from '@components/CouponBar'
import MerchandisingCouponBottomSheet from '@components/MerchandisingCouponBottomSheet'
import { filterCouponData, isEmpty } from '@utils/genralUtils'
import CouponBarLoader from './CouponBarLoader'

const NewCouponBarWrapper = ({ merchandisingData = [] }) => {
    const [merchBottomSheet, setMerchBottomSheet] = useState(null)
    // const data = filterCouponData({ data: merchandisingData, key: 'MERCH_DESC' })
    if (isEmpty(merchandisingData)) {
        return <CouponBarLoader />
    }
    return (
        <>
            <CouponBar merchandisingData={merchandisingData} setMerchBottomSheet={()=>{}} />
            {/* <MerchandisingCouponBottomSheet
                data={data}
                merchBottomSheet={merchBottomSheet}
                setMerchBottomSheet={setMerchBottomSheet}
            /> */}
        </>
    )
}

export default NewCouponBarWrapper
