// @ts-nocheck
import React, { useEffect, useState, memo, useRef } from 'react'
import dynamic from 'next/dynamic'
import SearchContainer from '@components/SearchContainer'
import Analytics from '@root/ui/utils/Analytics'
import { dataLayerGA } from '@utils/datalayer'
import { appendQuery, checkDeviceType, getDevicePlatform, isApp, isMsiteInAppWebview, isServer } from '@utils/appAgent'
import { sendClevertapUIActionEvent as CleverTapEvent } from '@utils/loginClevertap'
import { Container } from '@ct-design/lib'

import { BackArrow } from '@assests/svg'
import { isUserSignedIn } from '@utils/flights'
const Toast = dynamic(() => import(/* webpackChunkName: "common-Home" */ '@lib/Toast'), {
    ssr: false,
    loading: () => <></>
})

function HomePageContainer(props) {
    const { query } = props || {}
    const [vertical, verticalSelected] = useState('flight')
    const [locationData, setLocationData] = useState({})
    const [showToast, setShowToast] = useState({
        isVisible: false
    })
    const [queryObj, setQueryForm] = useState({
        travellers: { adults: 1, children: 0, infants: 0 },
        flightClass: 'Economy'
    })
    const initialPageLoadTime = useRef(0)

    let timer = null
    let queryParams = isApp(query)

    useEffect(() => {
        setTimer()
        return function cleanup() {
            clearTimeout(timer)
        }
        //eslint-disable-next-line
    }, [showToast.isVisible])
    const setTimer = () => {
        // clear any existing timer
        if (timer != null) clearTimeout(timer)

        // hide after delay
        timer = setTimeout(() => {
            hideToast()
            timer = null
        }, 2500)
    }
    const hideToast = () => {
        setShowToast({
            isVisible: false,
            message: ''
        })
    }
    const isMobileApp = queryParams && queryParams.isMobileApp === 'true'

    useEffect(() => {
        initialPageLoadTime.current = new Date().getTime()
        // if (!isEmpty(props.abTest) && !hasEffectRun) {
        Analytics.event('cleartrip home viewed', {
            platform: 'm-web-pwa',
            page_name: 'flights_home'
        })
        const payload = {
            // 'user_status':'{{dynamic}}',
            section: 'Flights'
        }
        if (
            (isMobileApp && getDevicePlatform() === 'iOS') ||
            (typeof window.sessionStorage != 'undefined' && sessionStorage.getItem('mobileApp') === 'iPhoneApp')
        ) {
            setTimeout(() => {
                dataLayerGA(payload, 'home_page_land')
            }, 5000)
        } else {
            dataLayerGA(payload, 'home_page_land')
        }

        let ravenPayLoadNew = {
            page_name: 'flights_home',
            a_utm_source:
                location.search.split('utm_source=').length > 1
                    ? location.search.split('utm_source=').pop().split('&')[0]
                    : 'organic',
            //page_loadtime: new Date().getTime() - initialTS || 'N/A',
            last_page_name:
                location.search.split('utm_source=').length > 1
                    ? location.search.split('utm_source=').pop().split('&')[0]
                    : 'unified_homepage',
        }

        const pageLoadTime = parseInt(new Date().getTime() - initialPageLoadTime.current)

    }, [])

    const triggerClevertap = ({ actionName, additionalData = {} }) => {
        CleverTapEvent({
            actionName,
            additionalData
        })
    }
    const handleNewTabChange = value => {
        verticalSelected(value)
        let deviceType = checkDeviceType()
        if (deviceType === 'iPhoneApp') {
            deviceType = 'iOS'
        } else if (deviceType === 'AndroidApp') {
            deviceType = 'Android'
        } else {
            deviceType = 'pwa'
        }
        if (value === 'hotel') {
            triggerClevertap({
                actionName: 'hotels_tab_switch',
                additionalData: {
                    source: deviceType,
                    sourcePageName: 'HP'
                }
            })
            window.location.href = '/hotels' + appendQuery(queryParams)
        } else if (value === 'bus') {
            triggerClevertap({
                actionName: 'tab_switch',
                additionalData: {
                    tab_name: 'bus',
                    platform: deviceType,
                    sourcePageName: 'HP'
                }
            })
            window.location.href = '/bus' + appendQuery(queryParams)
        } else {
            try {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            } catch (e) {
                window.scrollTo({
                    top: 0,
                    behavior: 'auto'
                })
            }
        }
    }
    return (
        <>
            <div className="homePage">
                <div className="px-4 flex py-16">
                    <a href={'/'}>
                        <BackArrow color="#1a1a1a" />
                    </a>
                    <span className="fs-20 pl-16 fw-600">Flights</span>
                </div>

                <SearchContainer
                    imgArray={[]}
                    closeFromSRP={() => {}}
                    query={queryObj}
                    abTest={{}}
                    locationData={locationData}
                />
            </div>

            <div className="p-fixed b-0 w-100p "></div>

            <Toast isVisible={showToast.isVisible} message={showToast.message} />
        </>
    )
}
export default memo(HomePageContainer)
