import React from "react"

const HotelsOfferIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3447 3.56856C11.7207 3.24235 12.2793 3.24235 12.6553 3.56856L13.5892 4.37876C13.8212 4.58004 14.1328 4.66354 14.4344 4.60522L15.6482 4.3705C16.1369 4.27599 16.6207 4.55532 16.7833 5.02583L17.1869 6.19439C17.2872 6.48471 17.5153 6.71284 17.8056 6.81312L18.9742 7.21675C19.4447 7.37926 19.724 7.86309 19.6295 8.35181L19.3948 9.56564C19.3365 9.8672 19.42 10.1788 19.6212 10.4108L20.4314 11.3447C20.7576 11.7207 20.7576 12.2793 20.4314 12.6553L19.6212 13.5892C19.42 13.8212 19.3365 14.1328 19.3948 14.4344L19.6295 15.6482C19.724 16.1369 19.4447 16.6207 18.9742 16.7833L17.8056 17.1869C17.5153 17.2872 17.2872 17.5153 17.1869 17.8056L16.7833 18.9742C16.6207 19.4447 16.1369 19.724 15.6482 19.6295L14.4344 19.3948C14.1328 19.3365 13.8212 19.42 13.5892 19.6212L12.6553 20.4314C12.2793 20.7576 11.7207 20.7576 11.3447 20.4314L10.4108 19.6212C10.1788 19.42 9.8672 19.3365 9.56564 19.3948L8.35181 19.6295C7.86309 19.724 7.37926 19.4447 7.21675 18.9742L6.81312 17.8056C6.71284 17.5153 6.48471 17.2872 6.19439 17.1869L5.02583 16.7833C4.55532 16.6207 4.27599 16.1369 4.3705 15.6482L4.60522 14.4344C4.66354 14.1328 4.58004 13.8212 4.37876 13.5892L3.56856 12.6553C3.24235 12.2793 3.24235 11.7207 3.56856 11.3447L4.37876 10.4108C4.58004 10.1788 4.66354 9.8672 4.60522 9.56564L4.3705 8.35182C4.27599 7.86309 4.55532 7.37926 5.02583 7.21675L6.19439 6.81312C6.48471 6.71284 6.71284 6.48471 6.81312 6.19439L7.21675 5.02583C7.37926 4.55532 7.86309 4.27599 8.35182 4.3705L9.56564 4.60522C9.8672 4.66354 10.1788 4.58004 10.4108 4.37876L11.3447 3.56856Z" fill="white" stroke="white" />
            <path d="M15.5999 8.40015L8.3999 15.6001" stroke="#F77728" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.34277 9.44604H9.36812" stroke="#F77728" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.7429 14.8462H14.7683" stroke="#F77728" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default HotelsOfferIcon
