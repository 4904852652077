import React, { PureComponent } from 'react'
import classNames from 'classnames'

import FooterFlights from '@utils/flights/Footer/config'
import GlobalFooter from 'Lib/GlobalFooter'
import HomePageContent from '@components/HomePageContent'
import FlightPageContent from '@components/FlightPageContent'

class Footer extends PureComponent {
    state = {
        readMore: false,
        showFooter: true
    }

    readMoreToggle = () => {
        this.setState({
            readMore: false
        })
    }
    render() {
        let classesBook = classNames('')
        if (this.state.readMore) {
            classesBook = classNames(`${classesBook} ReadMore__content`)
        }
        let toggleFooter = classNames('hideFooter')
        if (this.state.showFooter) {
            toggleFooter = classNames('showFooter')
        }
        let footerItems = Object.values(FooterFlights)
        let isFlightsPage = this.props.page == 'FLIGHT'
        return (
            <div className={toggleFooter} style={{ height: '100%', width: '100%' }}>
                {isFlightsPage ? (
                    <FlightPageContent classesBook={classesBook} country_code={this.props.locale}/>
                ) : (
                    <HomePageContent classesBook={classesBook} country_code={this.props.locale}/>
                )}
                
                <div className="Datalist mt-8 bt-0">
                    <GlobalFooter>
                        <div className="">
                            {footerItems.map(
                                item =>
                                    (item.text === 'Popular Airlines'|| item.text === 'Explore Other Links' || item.text === 'Countries' ) &&
                                    item[this.props.locale] &&
                                    !item.showOnlyForInDomain &&
                                    ((isFlightsPage && item.pages.includes('flights')) ||
                                        (!isFlightsPage && item.pages.includes('home'))) && (
                                        <>
                                            <p className="c-black-90 fw-600 lh-copy fs-14 pb-8 pt-8">{item.text} :</p>
                                            <div className="flex flex-wrap">
                                                {item[this.props.locale].map(flight => (
                                                    <a
                                                        key={flight.url} // Added key attribute
                                                        href={flight.url}
                                                        aria-label={flight.text}
                                                        className="fs-12 lh-copy pr-10 pb-5 d-ib c-blue"
                                                    >
                                                        {flight.text}
                                                    </a>
                                                ))}
                                            </div>
                                        </>
                                    )
                            )}
                        </div>
                    </GlobalFooter>
                </div>
            </div>
        )
    }
}

export default Footer
