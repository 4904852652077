import React, { Component } from "react";
import PropTypes from "prop-types";
import Pageloader from "@lib/Pageloader";
import Sheet from "@lib/Sheet";

class IFrame extends Component {
  state = {
    isLoading: true,
  };
  componentDidMount() {
    if (this.iframe) {
      this.iframe.onload = () => {
        this.setState({ isLoading: false });
      };
    }
    if (this.props.hashUrl) {
      window.addEventListener("hashchange", this.handleHashChange);
    }
  }

  componentWillUnmount() {
    if (this.props.hashUrl) {
      window.removeEventListener("hashchange", this.handleHashChange);
    }
  }

  style = { width: "100%", height: "100%" };

  _handleRef = (frame) => {
    this.iframe = frame;
  };

  handleHashChange = (e) => {
    let oldHash = e.oldURL.split("#")[1];
    if (oldHash === this.props.hashUrl) {
      this.props.onClose("closeButton");
    }
  };

  render() {
    return (
      <div style={this.style}>
        {this.state.isLoading && (
          <Pageloader title="Hang On" message={this.props.message} />
        )}

        <Sheet
          isOpen={this.props.isOpen}
          title={this.props.sheetTitle}
          hashUrl={this.props.hashUrl}
          onClose={this.props.onClose}
        >
          <iframe
            style={this.style}
            title={this.props.title}
            src={this.props.URL}
            ref={this._handleRef}
          >
            {this.props.children}
          </iframe>
        </Sheet>
      </div>
    );
  }
}

IFrame.propTypes = {
  URL: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  sheetTitle: PropTypes.string,
  onClose: PropTypes.func,
  hashUrl: PropTypes.string,
};

IFrame.defaultProps = {
  URL: "",
  title: "",
  message: "",
  children: null,
  hashUrl: null,
  isOpen: false,
  sheetTitle: "",
  onClose: () => {},
};

export default IFrame;
