import HomePageFaq from '@components/HomePageFaq'
import { useState } from 'react'
import {homePageContents} from '@utils/Contents/homePageContents.js'

const HomePageContent = ({classesBook , country_code}) => {
    const [readMore, setReadMore] = useState(false)
    // Getting contents based on country code or defaulting to AE
    const { heading,faqs,content } = homePageContents[country_code] || {};
    return (
        <div className="Datalist mt-8 bt-0">
            <div className="Datalist__item bb-0">
                <h1 className="fs-18 lh-title fw-600 c-neutral-900">
                    {heading}
                </h1>
                <div className={`${classesBook}`}>
                    {content}
                </div>
                <HomePageFaq data={faqs} />
                {readMore && (
                    <p className="fs-13 c-blue ta-l lh-copy pt-5 fs-16 pb-20" onClick={this.readMoreToggle}>
                        Read More...
                    </p>
                )}
            </div>
        </div>
    )
}

export default HomePageContent
