// Content's for all the domains in the home page
const aeContent = () => {
    return (
        <div className="bg-white pb-10">
            <p className="fs-14 lh-copy pt-10 c-black-60">
                Cleartrip.ae is the leading destination for stress-free travel booking in the Middle East.
                We make it easy for anyone to book cheap flights online at any time of day or night, whether
                it's for domestic or international travel via our seamless online experience.
            </p>
            <p className="fs-14 lh-copy pt-10 c-black-60">
                With over 400 airlines, 20,000+ activities, 400,000 hotels, many payment avenues, and an
                easy-to-use platform, Cleartrip.ae ensures that you will find the greatest pricing and most
                exciting travel deals. Thanks to our convenient cancellation policy and free rescheduling
                fares, Cleartrip.ae caters to your evolving travel needs, making your journey easy and
                hassle-free.
            </p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">
                Booking Flights &amp; Hotels Online with Cleartrip.ae
            </h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">
                With Cleartrip.ae you will find the best travel deals for flights and hotels with our low
                rates and extensive selection. Book your dream holiday easily on any mobile or desktop
                device using Cleartrip and avail amazing discounts on your travels.
            </p>
            <ul className="pt-2">
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">Easy &amp; Quick Booking</h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    Easy and quick booking is made possible with the intuitive Cleartrip.ae platform,
                    allowing you to secure flights and hotels in just a few clicks. Experience convenient
                    travel planning with fast, efficient service at your fingertips.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">
                        Book with flexibility (Flexifly)
                    </h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    Cleartrip's Flexifly offers free rescheduling with no additional fees, all you have to
                    do is just pay the fare difference if your plans change. Book early to secure low-cost
                    fares and save on higher rates and rescheduling fees. Simply select your flight, choose
                    Flexifly at booking, and request a reschedule when needed.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">Lowest Price</h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    The best deals for your travel needs are on Cleartrip.ae with some of the lowest rates
                    for flights and hotel reservations. With Cleartrip, you can book flight tickets that are
                    not only economical but also tailored to your specific preferences. Take advantage of
                    all the available possibilities for your travel needs with us, including getting your
                    hands on the lowest airfare of your choosing today.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">Exciting Offers &amp; Deals</h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    Indulge in incredible savings on flights, hotels, and activities with Cleartrip.ae's
                    amazing offers and deals.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">Travel Insurance</h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    No matter how well-planned your vacation or business trip is, something unexpected can
                    always happen that can lead to last-minute cancellations, getting some medical
                    attention, or even going home early. To ensure your safety on your journey, Cleartrip.ae
                    has developed Travel Guard, your travel insurance. Sign up for Travel Guard to ensure
                    the safety of your vacation.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">Easy Cancellation (EzCancel)</h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    Protect yourself against last-minute changes with Cleartrip's EzCancel, a simple process
                    to cancel flights without paying outrageous fees. Take advantage of up to 90% of
                    reimbursements for cancellations. Make your online cancellation easy by choosing
                    EzCancel when you make your reservation.
                </p>
            </ul>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">
                How Cleartrip.ae Can Help You to Fulfil Your Travel Needs?
            </h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">
                Cleartrip.ae optimises travel by offering an easy-to-use website for reserving flights,
                accommodations, and experiences at affordable prices and with special offers. There are 400+
                airlines and 400,000+ hotels around the world that you can book through Cleartrip.ae, using
                a variety of convenient payment options.
            </p>
            <p className="fs-14 lh-copy pt-10 c-black-60">
                We offer the most affordable rates, the best deals, and easy cancellation options so that
                you can plan your trip with ease and peace of mind. Additionally, customer satisfaction is
                our top priority, which is why we offer a dedicated helpline available 24/7 to address any
                questions or problems our customers may have.
            </p>
            <ul className="pt-2">
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">
                        Air Ticket Booking on Cleartrip.ae
                    </h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    With its user-friendly interface, cheap pricing, and large airline selection,
                    Cleartrip.ae makes it effortless to make your air ticket bookings, ensuring that you
                    will find the ideal flight for your travel.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h4 className="fs-16 lh-title fw-600 c-neutral-900">Domestic Air ticket booking.</h4>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    Experience the convenience of booking domestic air tickets on Cleartrip.ae. You can find
                    affordable rates and a diverse selection of flights with major airlines like Etihad,
                    Emirates, Flydubai, Air Arabia and many more, all tailored to meet your travel
                    requirements.
                </p>
                <li className="c-black-60 fs-14 pt-4">
                    <h4 className="fs-16 lh-title fw-600 c-neutral-900">
                        International Air ticket booking.
                    </h4>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    When you book your international flight tickets via Cleartrip.ae, you'll have access to
                    special rates, flexible rescheduling options, easy cancellation, and excellent customer
                    care to make sure your international trip goes off without a hitch. Fly with top-class
                    international airlines like British Airways, Emirates, Qatar Airways, Singapore
                    Airlines, Air Asia, Air Arabia, Flydubai, Etihad, Lufthansa, Air France, American
                    Airlines and many more.
                </p>
                <div align="left">
                    <table
                        style={{
                            margin: '4px 0',
                            border: 'none',
                            borderCollapse: 'collapse'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-cochin-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Kochi flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-mumbai-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Mumbai flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-new-delhi-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to New Delhi flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/sharjah-trivandrum-flights.html"
                                        >
                                            <u>
                                                <span>Sharjah to Trivandrum flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-hyderabad-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Hyderabad flights </span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-calicut-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Kozhikode flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/abu-dhabi-cochin-flights.html"
                                        >
                                            <u>
                                                <span>Abu Dhabi to Kochi flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/dubai-chennai-flights.html"
                                        >
                                            <u>
                                                <span>Dubai to Chennai flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-schedule/mumbai-dubai-flights.html"
                                        >
                                            <u>
                                                <span>Mumbai to Dubai flights</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <li className="c-black-60 fs-14 pt-4">
                    <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">
                        Hotels Booking Online with Cleartrip.ae
                    </h3>
                </li>
                <p className="fs-14 lh-copy pt-10 c-black-60">
                    Finding the ideal place to stay on your travels has never been easier than with
                    Cleartrip.ae's user-friendly platform, a wide selection of hotels with options ranging
                    from budget to luxurious, and unique promotions. Get the most out of your money with
                    easy booking, low costs, and attentive customer service.
                </p>
                <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8" >Our Top Airlines :</h2>
                <div align="left">
                    <table
                        style={{
                            margin: '4px 0',
                            border: 'none',
                            borderCollapse: 'collapse'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/indigo-airlines.html"
                                        >
                                            <u>
                                                <span>Indigo</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/flydubai-airlines.html"
                                        >
                                            <u>
                                                <span>Flydubai</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>

                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/air-india-express-airlines.html"
                                        >
                                            <u>
                                                <span>Air India Express</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/pakistan-international-airlines.html"
                                        >
                                            <u>
                                                <span>PIA Airlines</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/air-india-airlines.html"
                                        >
                                            <u>
                                                <span>Air India</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/etihad-airways-airlines.html"
                                        >
                                            <u>
                                                <span>Etihad Airways</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/air-arabia-airlines.html"
                                        >
                                            <u>
                                                <span>Air Arabia</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/qatar-airways-airlines.html"
                                        >
                                            <u>
                                                <span>Qatar Airways</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/emirates-airlines.html"
                                        >
                                            <u>
                                                <span>Emirates</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                                <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                    <p className="fs-16 lh-20 pb-5">
                                        <a
                                            style={{ color: '#1155cc', fontSize: '11pt' }}
                                            href="https://www.cleartrip.ae/flight-booking/wizz-air-airlines.html"
                                        >
                                            <u>
                                                <span>Wizz Air</span>
                                            </u>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ul>
        </div>
    )
}

const qaContent = () => {
    return (
        <div className="bg-white pb-10">
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.qa is your one-stop website to make the most of any travel in Qatar. Book flights, hotels, and vacation packages that complement your travel with the best services and facilities.</p>

            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Explore the Beauty of Qatar with Cleartrip.qa</strong>
            </h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip helps you experience the beauty of Qatar with amazing travel deals. From cultural spots to deserts book your journey with cleartrip.qa in no time!</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Discover Iconic Landmarks</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Qatar, the land of skyscrapers, has some iconic landmarks, old and new, from desert forts to hi-tech buildings.</p>
            <ul className="pt-2 ml-20" style={{ listStyleType: 'disc' }}>
                <li className="c-black-60 fs-14 pt-4">Doha Tower: Capture the magnificent design of the tall edifice even when dark and see the marvellous illumination at night.</li>
                <li className="c-black-60 fs-14 pt-4">Aspire Tower: Experience panoramic views from the high-rise with fine dining options on the terrace.</li>
                <li className="c-black-60 fs-14 pt-4">Tornado Tower: It is amazing to behold the spectacular hourglass structure at night when it is fully lit.</li>
                <li className="c-black-60 fs-14 pt-4">Sidra Public Art: Enjoy stunning and powerful bronze sculptures that signify the growth of humanity.</li>
                <li className="c-black-60 fs-14 pt-4">Qatar National Convention Centre: Go to the Sidra tree-shaped venue to see several cultural events.</li>
                <li className="c-black-60 fs-14 pt-4">Al Zubara Fort: The site is listed by UNESCO for those who are interested in the history of Qatar.</li>
                <li className="c-black-60 fs-14 pt-4">Barzan Towers: &nbsp;Rainwater storage bunkers in the form of beautiful historical tower homes.</li>
                <li className="c-black-60 fs-14 pt-4">Msheireb Mosque: A mix of modern and ancient structures incorporating the use of daylight. </li>
                <li className="c-black-60 fs-14 pt-4">Qatar National Library: It is an important cultural centre in Qatar, where free events are held for the public.</li>
            </ul>

            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Experience Traditional Souqs</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Visitors are encouraged to go to the souqs in order to experience most of the Qatari culture where small alleys house shops showcase and sell various items including dry fruits, garments, textiles, and gold jewellery.</p>
            <ul className="pt-2 ml-20" style={{ listStyleType: 'disc' }} >
                <li className="c-black-60 fs-14 pt-4">Souq waqif: Dedicated to the core activities, this marketplace in Doha is easily accessible by providing the locals with an assortment of Middle Eastern products &amp; cultural shows accompanied by the local cuisines.</li>
                <li className="c-black-60 fs-14 pt-4">Gold Souq: A wide range of gold ornaments is available here at attractive prices with traditional as well as a modern twist.</li>
                <li className="c-black-60 fs-14 pt-4">Souq Waqif Al Wakra: An exquisite beachside souq with shops, restaurants, and a lively atmosphere.</li>
                <li className="c-black-60 fs-14 pt-4">Souq Al Deira: A famous place for fabrics as well as textile materials in which many tailors and custom clothing are very much in use.</li>
                <li className="c-black-60 fs-14 pt-4">Souq Al Haraj: A bazaar for thrift goods and vintage </li>
                <li className="c-black-60 fs-14 pt-4">Souq Al Asmak: This souq is dedicated to selling seafood where people go to buy fresh fish and have a taste of Qatari food.</li>
                <li className="c-black-60 fs-14 pt-4">Souq Al Wakif Cultural Village: A place to shop as well as appreciate arts and crafts with an emphasis on the local culture.</li>
            </ul>

            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Enjoy Luxurious Resorts</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">The country houses various resorts that cater to tourists from all walks of life and interests, throughout the year, ensuring that they have a good time.</p>
            <ul className="pt-2 ml-20" style={{ listStyleType: 'disc' }}>
                <li className="c-black-60 fs-14 pt-4">5-Star Hotels: Experience the highest possible standard of hospitality available.</li>
                <li className="c-black-60 fs-14 pt-4">Private Beaches: &nbsp;Relax in the sun and the warm waters of the sea next to a pristine piece of land.</li>
                <li className="c-black-60 fs-14 pt-4">Sumptuous Spas: Pamper yourself with relaxing and refreshing treatments for the body and the soul.</li>
                <li className="c-black-60 fs-14 pt-4">Gourmet Dining: &nbsp;Enjoy some tempting cuisines from various corners of the world.</li>
                <li className="c-black-60 fs-14 pt-4">Exclusive Accommodations: Experience sleeping in villas, trying out some service apartments, or even going desert glamping.</li>
                <li className="c-black-60 fs-14 pt-4">Family-Friendly Facilities: Try out a wide range of fun-filled group activities that appeal to all age groups.</li>
                <li className="c-black-60 fs-14 pt-4">Private Islands: &nbsp;Live in luxury on an island away from the city.</li>
            </ul>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Visit Modern Museums</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Delve into the new-age museums in Qatar that revel in the art history and culture of the nation.</p>
            <ul className="pt-2 ml-20" style={{ listStyleType: 'disc' }}>
                <li className="c-black-60 fs-14 pt-4">National Museum of Qatar: Appreciate the extensive history and culture of Qatar and its population.</li>
                <li className="c-black-60 fs-14 pt-4">3-2-1 Qatar Olympic and Sports Museum: This is an unparalleled museum emphasizing not just the concept of sports but also the modern-day’s greatest sports initiative - The Olympics.</li>
                <li className="c-black-60 fs-14 pt-4">Mathaf: Arab Museum of Modern Art: This institution’s areas of focus are on modern and contemporary art of Qatar, the Middle and Arab world.</li>
                <li className="c-black-60 fs-14 pt-4">Museum of Islamic Art (MIA): One of the largest collections of Islamic art in the world and devoted to the Islamic heritage across the world.</li>
                <li className="c-black-60 fs-14 pt-4">Qatar Museums Gallery – Al Riwaq: A gallery that hosts some thought-provoking exhibitions</li>
                <li className="c-black-60 fs-14 pt-4">Qatar Museums Gallery – Katara: A small gallery located at Katara Cultural Village that showcases temporary exhibitions and public art.</li>
                <li className="c-black-60 fs-14 pt-4">Fire Station Garage Gallery: A venue for diverse contemporary art exhibitions located in Doha's Fire Station Art Centre.</li>
            </ul>
            <h2 className="fs-16  lh-title fw-600 c-neutral-900 pt-8"><strong>Exclusive Flight Booking and Hotel Reservations Options on Cleartrip.qa</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">With our cheap pricing and a vast number of choices with 400+ airlines, 400,000+ hotels, and 20,000+ activities, Cleartrip.qa is the best place to get travel bargains for flights and hotels. Sign up for your ideal vacation fast and easily on any desktop computer or mobile device with Cleartrip, and take advantage of incredible savings on your travel excursions. </p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Simple and Fast Booking Process</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Plan your entire vacation with ease, just use the simple Cleartrip.qa website to book all of your flights and lodgings. Make the most of our user-friendly website for an effortless vacation plan.</p>

            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Flexible Booking Options (FlexiFly)</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip's Flexifly service helps you to reschedule your flight at no additional cost. All you have to do to change your travel arrangements is pay the difference in fares. If you book your flight in advance, you can both avoid paying the high rates at peak times and save money on rescheduling. For easy flight rescheduling, choose Flexifly when you book.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Best Price Guarantee</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.qa has the best rates for cheap flights and hotels to help plan your vacation. Purchasing air tickets with Cleartrip is not only reasonable but also customizable to meet your specific needs. Find the best deal on airfare and take advantage of all the other services we offer to plan your vacation.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Exciting Offers and Discounts</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Discover incredible offers and discounts on airfare, hotels, and activities at Cleartrip.qa.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">24/7 Customer Support</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">In case there are any queries or problems that the travellers wish to rectify, they can touch base with the customer services of Cleartrip.qa which is available round the clock. Most if not all of your travel-related questions, be it making an air reservation, searching for details, rescheduling the flight and other pressing demands will be tackled by the Cleartrip customer care agents.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Comprehensive Travel Insurance</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Whether you're planning a vacation or a business trip in great detail, last-minute changes, medical issues, or an early departure are always possible. For your peace of mind, while travelling, Cleartrip.qa has developed Travel Guard, your travel insurance. Enrolling with Travel Guard will ensure that your vacation is protected.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Easy Cancellation (EzCancel)</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">With EzCancel from Cleartrip, you can rest easy knowing that your carefully planned holiday is safeguarded. You can still get 90% of your money back if you cancel your air ticket booking up to 10 hours preceding departure, even if your airline tickets are non-refundable. Any reservations for air tickets that are made within the first ninety days of joining EzCancel are approved, allowing you to relax and save money on other costs associated with cancellations.</p>

            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8">How Cleartrip.qa Enhances Your Travel Experience?</h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.qa makes it simple and easy to make plans for a trip. There is no need to worry while booking flights, hotels, and various activities at excellent prices and with great discounts owing to the user-friendly interface of Cleartrip.qa. Your one-stop site offers booking for over 20,000 activities, more than 400 airlines, and nearly 400,000 hotels with so many payment options available to choose from. We let you make the most of your trip offering the cheapest rates and the best of the options available without the hassle of many conditions for cancellation. Customer service is also at the top of the priorities since we also have a support line that is operated 24/7 with qualified personnel who are always ready to assist the customers.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Flight Booking Services</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">The user-friendly layout, best prices, hot travel deals, and wide selection of airlines on Cleartrip.qa make it easy to find the ideal flight for your holiday.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Domestic Flights within Qatar</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Experience the convenience of air ticket booking for domestic flights with Cleartrip.qa. To meet their travel needs cleartrip helps passengers book with Qatar Airways for a wide range of domestic flights at reasonable prices.</p>
            <h3 className="fs-16 lh-title pt-10 fw-600 c-neutral-900">International Flights from Qatar</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">When booking an international flight through Cleartrip.qa, you can rest assured that the trip will go smoothly thanks to features like affordable prices, flexible rescheduling options, easy cancellation, and excellent customer service. Take flight with any of the numerous illustrious airlines that serve the skies, including Qatar Airways British Airways, Singapore Airlines, Air France, Oman Air, KLM, Air Asia, Delta, Lufthansa, and countless more.</p>
            <div align="left">
                <table
                    style={{
                        margin: '4px 0',
                        border: 'none',
                        borderCollapse: 'collapse'
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/dubai-calicut-flights.html"
                                    >
                                        <u>
                                            <span>Dubai to Calicut Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/trivandrum-sharjah-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Sharjah Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/abu-dhabi-cochin-flights.html"
                                    >
                                        <u>
                                            <span>Abu Dhabi to Kochi Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/trivandrum-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Dubai Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/sharjah-varanasi-flights.html"
                                    >
                                        <u>
                                            <span>Sharjah to Varanasi Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.qa/flight-schedule/calicut-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Calicut to Dubai Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900"> International Flights to Qatar</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Using Cleartrip.qa to book your international airline tickets will make it much simpler for you to organize your travel to Qatar. Take advantage of the first-rate service and pleasant flights on board some of the world's finest airlines, including Qatar Airways, Emirates, British Airways, Japan Airlines, Malaysia Airlines, easyJet, Gulf Air, Aeroflot Russian Airlines, as well as Lufthansa.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Hotels Booking Online with Cleartrip.qa</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Thanks to Cleartrip.qa and its uncomplicated interface, finding the ideal accommodation for your holiday has never been simpler. It provides a wide range of hotels, from those on a tight budget to those with five-star amenities, and it often runs promotions that allow you to save money while enjoying perks like effortless air ticket booking, fair pricing, and top-notch service.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Luxury Hotels in Qatar</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Experience lavish luxury hospitality at the best hotels in Qatar, with rich décor combined with polite skilled personnel. The diversity of the hotels in Qatar ranges from, resorts by the beach to hotels on top of skyscrapers.</p>
            <h3 className="fs-16 lh-title pt-10 fw-600 c-neutral-900"> Budget-Friendly Accommodations</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Discover places to stay that are cheap but comfortable and easy to use. Stay at affordable prices with all the features that are useful if you travel within a tight budget!</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Family-Friendly Hotels</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Explore hotels are suitable for families where there is plenty of excitement and activities for every age group making the stay enjoyable for both parents and kids. With extra-large rooms and children’s menu restaurants, these hotels ensure a very successful family outing.</p>
            <h3 className="fs-16 pt-10 lh-title fw-600 c-neutral-900">Business Hotels</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">With excellent facilities of international standards, business hotels in Qatar provide a professional atmosphere with easy access to the Internet, meeting space, and luxurious services. They are easily located close to the major business districts which makes them more effective and comfortable for business travellers.</p>
        </div>
    )
}

const bhContent = () => {
    return (
        <div className="bg-white pb-10">
            <p className="fs-14 lh-copy pt-10 c-black-60">Experience peace of mind when it comes to travelling, with amazing offers and exceptional services just for you.</p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Explore the Wonders of Bahrain with Cleartrip.bh</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Plan your journey with Cleartrip.bh, where you can explore the beauty of Bahrain and enjoy your travel. Search through the list of top destinations and effortlessly book a plane for your travels.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Discover Historical Sites</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Bahrain's historical sites are a testament to its proud position as a cultural centre and ancient civilization for centuries. It is particularly famous in this respect due to the presence of three World Heritage centres: Qal’at Al Bahrain (Bahrain Fort), the Bahrain Pearling Trail and the Dilmun Burial Mounds. Beginning perhaps with the ancient A'Ali Royal Burial Mounds, the architecture of Al-Khamis Mosque and the mysterious Tree of Life, there are many monuments in Bahrain, which all revel in the history of the archipelago. These and traditional houses such as Beit Al Jasra present the richness of the history of the Kingdom and various influences that have shaped the nation’s identity.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Experience Local Markets</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">The local markets in Bahrain, such as Manama Souq, Muharraq Souq, and Souq Waqif provide a great insight into the culture and traditions of the Kingdom. Stacks of spices, piles of textiles and jewellery along with shops for traditional crafts are evidence of the glowing history of Bahrain as a chief Gulf trading region while also providing the true local’s life experience.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Enjoy Beach Resorts</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Beach resorts in Bahrain are a combination of high class, comfort and impressive views of the coastlines. Situated on the Arabian Gulf coast, these resorts lie on the coast with beautiful white sands and clear waters. With the calmness of secluded beaches and the excitement of water activities, guests can take pleasure in first-rate facilities such as endless swimming pools, spa treatments and excellent food for any occasion. Ranging from resorts for families with children to honeymoons, all of Bahrain’s beach resorts offer a pleasant holiday for travellers with stunning ocean vistas and warm heartfelt service.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Visit Cultural Institutions</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Bahrain features several active cultural institutions which work to promote creativity and respect the history of the land. The Bahrain National Museum represents the history of the country and the folk arts, while the Bahrain Fine Arts Society encourages the activities of local artists by organizing exhibitions. Moreover, the Sheikh Ebrahim Center for Culture and Research engages with the history of Bahrain through lectures, workshops and performances. These institutions render Bahrain as one of the most active centres of culture within the Gulf region, worthy of exploration during your travels.</p>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Exclusive Flight and Hotel Booking Options on Cleartrip.bh</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.bh is the best option if looking for cheap air tickets along with extensive options because it has over 400 airlines, 400000 hotels and 20000 activities. Whether it’s a desktop or a mobile device, turn your imagination and start booking the dream vacation that you always wanted with the help of Cleartrip at great discounts on all your travel plans.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Simple and Fast Booking Process</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.bh has made every aspect of planning your vacation much simpler. You can book all your flights and accommodation via our site, and it is very easy even for a novice.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Flexible Booking Options (FlexiFly</strong>)</h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">With Cleartrip’s Flexifly service, you can change the date of your flight and bear only the difference in airfare. As long as you book ahead of time, your chances of being faced with high peak-time prices are slim, and if you have to reschedule your flight, the charges will be minimal. Go for Flexifly when booking air tickets to avoid difficulties in further changes to your travel arrangements.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Best Price Guarantee</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.bh allows you to take the best values available when looking for cheap flights and cheap hotels which will enable you to plan a low-budget trip. You can book through Cleartrip which will provide you with images of those options that have been tailored for your liking thus ensuring that you get the most affordable airfare from the wide range of services that we offer.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Exciting Offers and Discounts</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Save your searching time and get more special offers for tickets, hotel, and activities packages at cleartrip.bh.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>24/7 Customer Support</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">In case of queries or concerns, Cleartrip’s&nbsp;Customer Service is at help round the clock. No matter if you require assistance in air ticket booking, looking for travel information, or changing your travel itinerary, our support division will serve all your travel requirements.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Comprehensive Travel Insurance</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Cleartrip.bh extends the facilities of Travel Guard, a travel insurance service offering insurance protection against trip interruption cover in case of any eventuality or medical issues or anything why one is forced to depart from the tour early. You will not have to think twice before booking any vacation now thanks to Travel Guard.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Easy Cancellation (EzCancel)</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Because of Cleartrip’s EzCancel, your vacation plans are protected with ease. Even with a nonrefundable airline ticket, travellers can cancel and still recover 90% of their money when they raise the cancellation request 10 hours before their flight. EzCancel is applicable within the first 90 days of a new membership, helping travellers save on cancellation fees and enjoy a worry-free experience.</p>

            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Enhance Your Travel Experience with Cleartrip.bh</strong></h2>
            <p className="fs-14 lh-copy pt-10 c-black-60">Planning trips is easy using Cleartrip.bh. It has an easy-to-use interface that enables you to buy tickets, get a hotel, or book fun activities at great prices and discounts. Cleartrip.bh covers around 20,000 activities, more than 400 airlines, and approximately 400,000 hotels and offers any payment option you may prefer. We offer the most competitive prices literally every day including the very low cancellation fee, with no additional conditions. Customer support is equally important to Cleartrip.bh ensuring there is a team available seven days a week, round the clock to respond to any issue a customer may have and needs clarity on.</p>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Flight Booking Services</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">Whether it's the user-friendly design, the favourable pricing, the exotic travel packages or even the extensive list of airlines, finding a suitable flight for your holiday is a breeze on Cleartrip.bh</p>
            <h4 className="fs-16 lh-title fw-600 c-neutral-900"><strong>Domestic Flights within Bahrain</strong></h4>
            <p className="fs-14 lh-copy pt-10 c-black-60">Take advantage of stress-free booking of domestic flights on Cleartrip.bh. We help passengers who wish to fly with Gulf Air to different domestic destinations at cheap rates.</p>

            <h4 className="fs-16 lh-title fw-600 c-neutral-900"><strong>International Flights from Bahrain</strong></h4>
            <p className="fs-14 lh-copy pt-10 c-black-60">When booking international tickets through Cleartrip.bh, you can rest assured of a smooth travel process. This is made possible by features such as cheap fares, flexible changes, easy cancellations, and strong service. Domestic airlines as well as international ones including Gulf Air, Qatar Airways, British Airways, Peach Aviation, Singapore Airlines, Air Arabia, Oman Air, KLM, Turkish Airlines, Jazeera Airways, Lufthansa, easyJet etc. are some of the options you can choose from.</p>
            <div align="left">
                <table
                    style={{
                        margin: '4px 0',
                        border: 'none',
                        borderCollapse: 'collapse'
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/dubai-calicut-flights.html"
                                    >
                                        <u>
                                            <span>Dubai to Calicut Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/trivandrum-sharjah-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Sharjah Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/abu-dhabi-cochin-flights.html"
                                    >
                                        <u>
                                            <span>Abu Dhabi to Kochi Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/trivandrum-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Trivandrum to Dubai Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/sharjah-varanasi-flights.html"
                                    >
                                        <u>
                                            <span>Sharjah to Varanasi Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                            <td style={{ border: 'solid #dedede 1pt', padding: '10px' }}>
                                <p className="fs-16 lh-20 pb-5">
                                    <a
                                        style={{ color: '#1155cc', fontSize: '11pt' }}
                                        href="https://www.cleartrip.bh/flight-schedule/calicut-dubai-flights.html"
                                    >
                                        <u>
                                            <span>Calicut to Dubai Flights</span>
                                        </u>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"> <strong>International Flights to Bahrain</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">When flying overseas, book international flights on cleartrip.bh makes your travel arrangements to Bahrain less cumbersome. Fly some of the most prominent airlines all over the world which include Gulf Air, Emirates, British Airways, Qatar Airways, Kenya Airways, Malaysia Airlines, Northwestern Air, Aeroflot, Spirit Airlines and Ethiopian Airlines.</p>

            <h3 className="fs-16 lh-title fw-600 c-neutral-900 pt-8"><strong>Hotels Booking Online with Cleartrip.bh</strong></h3>
            <p className="fs-14 lh-copy pt-10 c-black-60">With the ease of use of the Cleartrip.bh website, it is very easy for you to look for accommodation that is fitting for your planned travels. It also provides a variety of hotels ranging from cheap ones to 5 stars, along with other deals allowing you to make your reservations at the best prices and get the best services available.</p>

            <h4 className="fs-16 lh-title fw-600 c-neutral-900"><strong>Luxury Hotels in Bahrain</strong></h4>
            <p className="fs-14 lh-copy pt-10 c-black-60">Experience the warmth and comfort of world-class hospitality at the best hotels in Bahrain where fine decor goes hand in hand with good attention to the clients. The places of accommodation include resorts and five-star hotels that are beautifully designed and crafted architecturally.</p>

            <h4 className="fs-16 lh-title fw-600 c-neutral-900"><strong>Budget Friendly Hotels in Bahrain</strong></h4>
            <p className="fs-14 lh-copy pt-10 c-black-60">Get cheap yet clean accommodations and comfortable lodging places that have basic facilities for travellers on a limited budget.</p>

            <h4 className="fs-16 lh-title fw-600 c-neutral-900"><strong>Family Friendly Hotels in Bahrain</strong></h4>
            <p className="fs-14 lh-copy pt-10 c-black-60">Explore hotels which provide family-friendly facilities and activities to suit all ages making the stay for parents and children a memorable one. These hotels are also remarkable family vacation facilities since they have wide rooms and even restaurants with children’s menus.</p>

            <h4 className="fs-16 lh-title fw-600 c-neutral-900"><strong>Business Hotels in Bahrain</strong></h4>
            <p className="fs-14 lh-copy pt-10 c-black-60">There are several hotels in the Kingdom of Bahrain suitable for business travellers with international standards providing stylish environments including high-speed internet services, meeting rooms and fine services. These hotels are near popular business areas to accommodate the busy professionals wanting comfort and ease.</p>
        </div>
    )
}



// Faq's for all the domains in the home page
const aeFaq = () => {
    return [
        {
            heading: '1. How do I find the cheapest flights using Cleartrip.ae?',
            values: [
                {
                    type: 'text',
                    value: 'To discover the cheapest airfare with Cleartrip.ae, all you have to do is choose your preferred flight and browse the many deals and promotions displayed on the booking page. Make the most of your money by taking advantage of sales and promo codes.'
                }
            ]
        },
        {
            heading: '2. What are the best deals offered for flight bookings on Cleartrip.ae?',
            values: [
                {
                    type: 'text',
                    value: "Easy and cost-effective booking is made possible by Cleartrip.ae's year-round promotions and offers on online flight tickets. Additionally, keep a look out for exciting sales events where Cleartrip offers the finest travel bargains. Be sure to check back later to find out about all the latest deals and discounts for air ticket booking on Cleartrip.ae."
                }
            ]
        },
        {
            heading: '3. How can I find the best discounts and offers on hotel bookings with Cleartrip.ae?',
            values: [
                {
                    type: 'text',
                    value: 'Finding the best deals for your stay is made easy with Cleartrip.ae. To get the greatest value for your stay, just select the hotel of your choice and browse the different offers and promo codes that are available on the booking page.'
                }
            ]
        },
        {
            heading: '4. What is the best time to book airline tickets on Cleartrip.ae?',
            values: [
                {
                    type: 'text',
                    value: 'The best time to purchase plane tickets on Cleartrip.ae varies based on factors such as travel dates, demand, and destination. Better deals are typically available for those who plan and are flexible with their vacation dates.'
                }
            ]
        },
        {
            heading: '5. How does the Cleartrip app streamline the process of booking flights & hotels for travellers?',
            values: [
                {
                    type: 'text',
                    value: "Cleartrip's award-winning mobile app and desktop solutions simplify the travel booking process with our user-friendly interface, which offers access to over 400 airlines, 400,000 hotels, and 20,000+ activities. With a variety of payment options, the best prices, and hot deals available, travellers can easily book flights and hotels with options for free rescheduling and hassle-free cancellations."
                }
            ]
        }
    ]

}

const qaFaq = () => {
    return [
        {
            heading: '1. How do I find the cheapest flights on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'If you want to discover the cheapest airfare with Cleartrip.qa, all you have to do is choose your flight and look at all the deals and discounts displayed on the booking page. To maximize your budget, try searching for sales or promotional codes.'
                }
            ]
        },
        {
            heading: '2. What are the best deals offered for flight bookings on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: "Cleartrip.qa tries to make booking tickets on deals an easy task for its customers throughout the year. Additionally, Cleartrip frequently hosts amazing sales events, during which customers have the opportunity to score exciting affordable travel offers. Keep checking the website for the latest information regarding Cleartrip.qa's flight offers."
                }
            ]
        },
        {
            heading: '3. How can I find the best discounts and offers on hotel bookings with Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'To get yourself the most advantageous accommodations all you need to do is book it through Cleartrip.qa. Just visit the booking page of the hotel of your choice to go over the available discounts and special offers.'
                }
            ]
        },
        {
            heading: '4. What is the best time to book airline tickets on Cleartrip.qa?',
            values: [
                {
                    type: 'text',
                    value: 'When making the purchase of airline tickets on Cleartrip.qa, specific factors such as your travel dates and destination’s peak season have to be considered. More often than not, holidaymakers can decide to be flexible in their dates of travel and think ahead to save some money.'
                }
            ]
        },
        {
            heading: '5. How does the Cleartrip app streamline the booking process for travellers?',
            values: [
                {
                    type: 'text',
                    value: "Booking a trip is a breeze with Cleartrip's award-winning desktop and smartphone apps, thanks to their intuitive user interface. With its access to more than 20,000 attractions, 400 airlines, and 400,000 hotels, it streamlines the trip planning process. Fast and easy hotel and airfare bookings with a variety of payment methods and the best offers around. Reservations can be easily changed or cancelled at no further cost to the customer."
                }
            ]
        }
    ]
}

const bhFaq = () => {
    return [
        {
            "heading": "1. How do I find the cheapest flights on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "To get the most affordable flights while using Cleartrip.bh, it is advisable to choose a flight and check the offers issued on the screen provided on the ticketing page. To get the most out of your budget, look out for updates which may contain coupons or various promotions."
                }
            ]
        },
        {
            "heading": "2. What are the best deals offered for flight bookings on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "With Cleartrip.bh, customers can purchase tickets every season of the year thanks to the availability of the right deals. Additionally, the site also has terrific sales on a regular basis, enabling travellers to get exciting, cheap travel packages. Check the Cleartrip.bh online site frequently to see if there are any promo codes or new flight sales updates."
                }
            ]
        },
        {
            "heading": "3. How can I find the best discounts and offers on hotel bookings with Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "For your accommodation needs, use Cleartrip.bh to secure the best rates possible. You may proceed to your desired hotel’s page on Cleartrip and click on special offers to check what deals are active."
                }
            ]
        },
        {
            "heading": "4. What is the best time to book airline tickets on Cleartrip.bh?",
            "values": [
                {
                    "type": "text",
                    "value": "There are some things that one should consider when purchasing airline tickets via Cleartrip.bh, for example, the weather, flight dates, and peak seasons of the destination place. Moreover, users are encouraged to be adaptable with their dates in case there are better deals on purchasing air tickets."
                }
            ]
        },
        {
            "heading": "5. How does the Cleartrip app streamline the booking process for travellers?",
            "values": [
                {
                    "type": "text",
                    "value": "With Cleartrip’s award-winning desktop and mobile apps, trip planning is seamless, featuring a user-friendly interface. With access to over 20,000 activities, 400 airlines, and 400,000 hotels, the process of booking flights and hotels is simplified on our website. You can quickly make your reservations using a variety of payment methods, taking advantage of the best offers and deals. Modifications and cancellations can be easily made at no extra cost to the customer."
                }
            ]
        }
    ]
}


export const homePageContents = {
    AE: {
        heading: 'Flight Search Made Easy: Why Choose Cleartrip.ae?',
        faqs: aeFaq(),
        content: aeContent()
    },
    QA: {
        heading: 'Welcome to Cleartrip.qa - Your Ultimate Travel Partner in Qatar',
        faqs: qaFaq(),
        content: qaContent()
    },
    BH: {
        heading: 'Welcome to Cleartrip.bh - Your Premier Flight Booking Partner in Bahrain',
        faqs: bhFaq(),
        content: bhContent()
    }
}