import { Typography } from '@ct-design/lib'
import Dots from '@components/Dots'
import React from 'react'
interface ItineraryProps {
    loaderText?: string
}
const ItineraryLoader = ({ loaderText }: ItineraryProps) => {
    return (
        <div className="flex flex-1 flex-center flex-column flex-middle h-100vh" style={{background: '#fff'}}>
            <Dots variant={'dark'} />
            {loaderText && (
                <Typography variant="B2" className="mt-2">
                    {loaderText}
                </Typography>
            )}
        </div>
    )
}

export default ItineraryLoader
