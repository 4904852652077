import { RefObject, useRef, useEffect, MouseEvent, useState } from 'react'
import { Container, Typography } from "@ct-design/lib"
import IFrame from '@lib/IFrame'
interface HotelsCrossSellBlockProps {
    Data?: any
    confirmationData?: any
}

const OfferBlock = ({
    Data = {},

}: HotelsCrossSellBlockProps) => {
    const [iframeLink, setIframeLink] = useState<String | null>(null)
    const [showIframe, setShowIframe] = useState(false)
    const handleCopyToClipboard = (e: MouseEvent<HTMLButtonElement>) => {
        // e?.stopPropagation()
        // navigator?.clipboard?.writeText(couponCode)   
    }

    const handleIframe = (link: string, title: string) => {
        setIframeLink(link)
        setShowIframe(true)
    }

    return (
        <>
            <Container
                className="br-12 pl-4 pr-4"
            >
                <Container className="pb-5 pt-3 pl-4" >
                    <Container className="flex flex-between flex-center ">
                        <Container>
                            <Container
                                className="pt-1"
                                onClick={() => handleIframe(Data[0].targetLink, Data[0].title)}
                            >
                                <img style={{ width: "100%", height: "100%", borderRadius: "12px" }} src={Data[0].imageUrl} />
                                {/* <div
                                className="fs-17 fw-400 lh-30"
                                style={{ width: "324px" }}
                            >
                                <b>Freedom to Travel, Freedom to Save!</b>
                            </div>
                            <div
                                className="fs-15 fw-400 lh-22 c-green-500"
                                style={{ width: "324px" }}
                            >
                                Book your flight and get up to AED 152 off
                            </div> */}
                            </Container>
                            {/* <Container className="mt-2" style={{ marginLeft: "1rem" }}>
                            <HotelCouponTag
                                couponCode={'CTBEST'}
                                copy={true}
                                couponIcon={<HotelsOfferIcon />}
                                onCopy={handleCopyToClipboard}
                            />
                        </Container> */}
                        </Container>
                        {/* <Image src={HotelCrossSellIcon} alt="hotelIcon"/> */}
                    </Container>
                </Container>
            </Container>
            {showIframe && iframeLink && (
                <IFrame
                    URL={Data[0].targetLink}
                    title="Offers"
                    message="Loading offers"
                    isOpen={showIframe}
                    sheetTitle="Offers"
                    onClose={() => setShowIframe(false)}
                />

            )}

        </>
    )



}

export default OfferBlock