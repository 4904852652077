export default {
    flightsPopular: {
        text: 'Popular Routes',
        pages: ['flights', 'home'],
        IN: [
            {
                url: '/flight-schedule/new-delhi-goa-flights.html',
                text: 'Delhi Goa flights'
            },
            {
                url: '/flight-schedule/mumbai-new-delhi-flights.html',
                text: 'Mumbai Delhi flights'
            },
            {
                url: '/flight-schedule/new-delhi-kolkata-flights.html',
                text: 'Delhi Kolkata flights'
            },
            {
                url: '/flight-schedule/pune-new-delhi-flights.html',
                text: 'Pune Delhi flights'
            },
            {
                url: '/flight-schedule/bangalore-new-delhi-flights.html',
                text: 'Bangalore Delhi flights'
            },
            {
                url: '/flight-schedule/mumbai-bangalore-flights.html',
                text: 'Mumbai Bangalore flights'
            },
            {
                url: '/flight-schedule/chennai-new-delhi-flights.html',
                text: 'Chennai Delhi flights'
            },
            {
                url: '/flight-schedule/kolkata-new-delhi-flights.html',
                text: 'Kolkata Delhi flights'
            },
            {
                url: '/flight-schedule/new-delhi-mumbai-flights.html',
                text: 'Delhi Mumbai flights'
            },
            {
                url: '/flight-schedule/new-delhi-bangalore-flights.html',
                text: 'Delhi Bangalore flights'
            },
            {
                url: '/flight-schedule/new-delhi-dubai-flights.html',
                text: 'Delhi Dubai flights'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai flights'
            },
            {
                url: '/flight-schedule/new-delhi-bangkok-flights.html',
                text: 'Delhi Bangkok flights'
            },
            {
                url: '/flight-schedule/new-delhi-singapore-flights.html',
                text: 'Delhi Singapore flights'
            },
            {
                url: '/flight-schedule/mumbai-goa-flights.html',
                text: 'Mumbai Goa flights'
            }
        ],
        undefined: [],
        AE: [
            {
                url: '/flight-schedule/dubai-mumbai-flights.html',
                text: 'Dubai to Mumbai Flights'
            },
            {
                url: '/flight-schedule/dubai-cochin-flights.html',
                text: 'Dubai to Kochi Flights'
            },
            {
                url: '/flight-schedule/dubai-chennai-flights.html',
                text: 'Dubai to Chennai Flights'
            },
            {
                url: '/flight-schedule/dubai-hyderabad-flights.html',
                text: 'Dubai to Hyderabad Flights'
            },
            {
                url: '/flight-schedule/dubai-calicut-flights.html',
                text: 'Dubai to Kozhikode Flights'
            },
            {
                url: '/flight-schedule/sharjah-trivandrum-flights.html',
                text: 'Sharjah to Trivandrum Flights'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai to Dubai Flights'
            },
            {
                url: '/flight-schedule/dubai-lucknow-flights.html',
                text: 'Dubai to Lucknow Flights'
            },
            {
                url: '/flight-schedule/dubai-kathmandu-flights.html',
                text: 'Dubai to Kathmandu Flights'
            },
            {
                url: '/flight-schedule/sharjah-cochin-flights.html',
                text: 'Sharjah to Kochi Flights'
            },
            {
                url: '/flight-schedule/cochin-dubai-flights.html',
                text: 'Kochi to Dubai Flights'
            },
            {
                url: '/flight-schedule/sharjah-calicut-flights.html',
                text: 'Sharjah to Kozhikode Flights'
            },
            {
                url: '/flight-schedule/sharjah-chennai-flights.html',
                text: 'Sharjah to Chennai Flights'
            },
            {
                url: '/flight-schedule/sharjah-new-delhi-flights.html',
                text: 'Sharjah to Delhi Flights'
            },
            {
                url: '/flight-schedule/cochin-sharjah-flights.html',
                text: 'Kochi to Sharjah Flights'
            },
            {
                url: '/flight-schedule/sharjah-mumbai-flights.html',
                text: 'Sharjah to Mumbai Flights'
            },
            {
                url: '/flight-schedule/mumbai-sharjah-flights.html',
                text: 'Mumbai to Sharjah Flights'
            },
            {
                url: '/flight-schedule/sharjah-lucknow-flights.html',
                text: 'Sharjah to Lucknow Flights'
            }
        ],
        SA: [
            {
                url: '/flight-schedule/dubai-mumbai-flights.html',
                text: 'Dubai Mumbai'
            },
            {
                url: '/flight-schedule/dubai-cochin-flights.html',
                text: 'Dubai Kochi'
            },
            {
                url: '/flight-schedule/dubai-chennai-flights.html',
                text: 'Dubai Chennai'
            },
            {
                url: '/flight-schedule/dubai-hyderabad-flights.html',
                text: 'Dubai Hyderabad'
            },
            {
                url: '/flight-schedule/dubai-calicut-flights.html',
                text: 'Dubai Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-trivandrum-flights.html',
                text: 'Sharjah Trivandrum'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai'
            },
            {
                url: '/flight-schedule/dubai-lucknow-flights.html',
                text: 'Dubai Lucknow'
            },
            {
                url: '/flight-schedule/dubai-kathmandu-flights.html',
                text: 'Dubai Kathmandu'
            },
            {
                url: '/flight-schedule/sharjah-cochin-flights.html',
                text: 'Sharjah Kochi'
            },
            {
                url: '/flight-schedule/cochin-dubai-flights.html',
                text: 'Kochi Dubai'
            },
            {
                url: '/flight-schedule/sharjah-calicut-flights.html',
                text: 'Sharjah Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-chennai-flights.html',
                text: 'Sharjah Chennai'
            },
            {
                url: '/flight-schedule/sharjah-new-delhi-flights.html',
                text: 'Sharjah Delhi'
            },
            {
                url: '/flight-schedule/cochin-sharjah-flights.html',
                text: 'Kochi Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-mumbai-flights.html',
                text: 'Sharjah Mumbai'
            },
            {
                url: '/flight-schedule/mumbai-sharjah-flights.html',
                text: 'Mumbai Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-lucknow-flights.html',
                text: 'Sharjah Lucknow'
            }
        ],
        OM: [
            {
                url: '/flight-schedule/dubai-mumbai-flights.html',
                text: 'Dubai Mumbai'
            },
            {
                url: '/flight-schedule/dubai-cochin-flights.html',
                text: 'Dubai Kochi'
            },
            {
                url: '/flight-schedule/dubai-chennai-flights.html',
                text: 'Dubai Chennai'
            },
            {
                url: '/flight-schedule/dubai-hyderabad-flights.html',
                text: 'Dubai Hyderabad'
            },
            {
                url: '/flight-schedule/dubai-calicut-flights.html',
                text: 'Dubai Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-trivandrum-flights.html',
                text: 'Sharjah Trivandrum'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai'
            },
            {
                url: '/flight-schedule/dubai-lucknow-flights.html',
                text: 'Dubai Lucknow'
            },
            {
                url: '/flight-schedule/dubai-kathmandu-flights.html',
                text: 'Dubai Kathmandu'
            },
            {
                url: '/flight-schedule/sharjah-cochin-flights.html',
                text: 'Sharjah Kochi'
            },
            {
                url: '/flight-schedule/cochin-dubai-flights.html',
                text: 'Kochi Dubai'
            },
            {
                url: '/flight-schedule/sharjah-calicut-flights.html',
                text: 'Sharjah Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-chennai-flights.html',
                text: 'Sharjah Chennai'
            },
            {
                url: '/flight-schedule/sharjah-new-delhi-flights.html',
                text: 'Sharjah Delhi'
            },
            {
                url: '/flight-schedule/cochin-sharjah-flights.html',
                text: 'Kochi Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-mumbai-flights.html',
                text: 'Sharjah Mumbai'
            },
            {
                url: '/flight-schedule/mumbai-sharjah-flights.html',
                text: 'Mumbai Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-lucknow-flights.html',
                text: 'Sharjah Lucknow'
            }
        ],
        KW: [
            {
                url: '/flight-schedule/dubai-mumbai-flights.html',
                text: 'Dubai Mumbai'
            },
            {
                url: '/flight-schedule/dubai-cochin-flights.html',
                text: 'Dubai Kochi'
            },
            {
                url: '/flight-schedule/dubai-chennai-flights.html',
                text: 'Dubai Chennai'
            },
            {
                url: '/flight-schedule/dubai-hyderabad-flights.html',
                text: 'Dubai Hyderabad'
            },
            {
                url: '/flight-schedule/dubai-calicut-flights.html',
                text: 'Dubai Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-trivandrum-flights.html',
                text: 'Sharjah Trivandrum'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai'
            },
            {
                url: '/flight-schedule/dubai-lucknow-flights.html',
                text: 'Dubai Lucknow'
            },
            {
                url: '/flight-schedule/dubai-kathmandu-flights.html',
                text: 'Dubai Kathmandu'
            },
            {
                url: '/flight-schedule/sharjah-cochin-flights.html',
                text: 'Sharjah Kochi'
            },
            {
                url: '/flight-schedule/cochin-dubai-flights.html',
                text: 'Kochi Dubai'
            },
            {
                url: '/flight-schedule/sharjah-calicut-flights.html',
                text: 'Sharjah Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-chennai-flights.html',
                text: 'Sharjah Chennai'
            },
            {
                url: '/flight-schedule/sharjah-new-delhi-flights.html',
                text: 'Sharjah Delhi'
            },
            {
                url: '/flight-schedule/cochin-sharjah-flights.html',
                text: 'Kochi Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-mumbai-flights.html',
                text: 'Sharjah Mumbai'
            },
            {
                url: '/flight-schedule/mumbai-sharjah-flights.html',
                text: 'Mumbai Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-lucknow-flights.html',
                text: 'Sharjah Lucknow'
            }
        ],
        QA: [
            {
                url: '/flight-schedule/dubai-mumbai-flights.html',
                text: 'Dubai Mumbai'
            },
            {
                url: '/flight-schedule/dubai-cochin-flights.html',
                text: 'Dubai Kochi'
            },
            {
                url: '/flight-schedule/dubai-chennai-flights.html',
                text: 'Dubai Chennai'
            },
            {
                url: '/flight-schedule/dubai-hyderabad-flights.html',
                text: 'Dubai Hyderabad'
            },
            {
                url: '/flight-schedule/dubai-calicut-flights.html',
                text: 'Dubai Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-trivandrum-flights.html',
                text: 'Sharjah Trivandrum'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai'
            },
            {
                url: '/flight-schedule/dubai-lucknow-flights.html',
                text: 'Dubai Lucknow'
            },
            {
                url: '/flight-schedule/dubai-kathmandu-flights.html',
                text: 'Dubai Kathmandu'
            },
            {
                url: '/flight-schedule/sharjah-cochin-flights.html',
                text: 'Sharjah Kochi'
            },
            {
                url: '/flight-schedule/cochin-dubai-flights.html',
                text: 'Kochi Dubai'
            },
            {
                url: '/flight-schedule/sharjah-calicut-flights.html',
                text: 'Sharjah Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-chennai-flights.html',
                text: 'Sharjah Chennai'
            },
            {
                url: '/flight-schedule/sharjah-new-delhi-flights.html',
                text: 'Sharjah Delhi'
            },
            {
                url: '/flight-schedule/cochin-sharjah-flights.html',
                text: 'Kochi Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-mumbai-flights.html',
                text: 'Sharjah Mumbai'
            },
            {
                url: '/flight-schedule/mumbai-sharjah-flights.html',
                text: 'Mumbai Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-lucknow-flights.html',
                text: 'Sharjah Lucknow'
            }
        ],
        BH: [
            {
                url: '/flight-schedule/dubai-mumbai-flights.html',
                text: 'Dubai Mumbai'
            },
            {
                url: '/flight-schedule/dubai-cochin-flights.html',
                text: 'Dubai Kochi'
            },
            {
                url: '/flight-schedule/dubai-chennai-flights.html',
                text: 'Dubai Chennai'
            },
            {
                url: '/flight-schedule/dubai-hyderabad-flights.html',
                text: 'Dubai Hyderabad'
            },
            {
                url: '/flight-schedule/dubai-calicut-flights.html',
                text: 'Dubai Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-trivandrum-flights.html',
                text: 'Sharjah Trivandrum'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai'
            },
            {
                url: '/flight-schedule/dubai-lucknow-flights.html',
                text: 'Dubai Lucknow'
            },
            {
                url: '/flight-schedule/dubai-kathmandu-flights.html',
                text: 'Dubai Kathmandu'
            },
            {
                url: '/flight-schedule/sharjah-cochin-flights.html',
                text: 'Sharjah Kochi'
            },
            {
                url: '/flight-schedule/cochin-dubai-flights.html',
                text: 'Kochi Dubai'
            },
            {
                url: '/flight-schedule/sharjah-calicut-flights.html',
                text: 'Sharjah Kozhikode'
            },
            {
                url: '/flight-schedule/sharjah-chennai-flights.html',
                text: 'Sharjah Chennai'
            },
            {
                url: '/flight-schedule/sharjah-new-delhi-flights.html',
                text: 'Sharjah Delhi'
            },
            {
                url: '/flight-schedule/cochin-sharjah-flights.html',
                text: 'Kochi Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-mumbai-flights.html',
                text: 'Sharjah Mumbai'
            },
            {
                url: '/flight-schedule/mumbai-sharjah-flights.html',
                text: 'Mumbai Sharjah'
            },
            {
                url: '/flight-schedule/sharjah-lucknow-flights.html',
                text: 'Sharjah Lucknow'
            }
        ]
    },
    exploreLinks: {
        text: 'Explore Other Links',
        pages: ['flights', 'home'],
        IN: [
            {
                url: '/flight-schedule/new-delhi-goa-flights.html',
                text: 'Delhi Goa flights'
            },
            {
                url: '/flight-schedule/mumbai-new-delhi-flights.html',
                text: 'Mumbai Delhi flights'
            },
            {
                url: '/flight-schedule/new-delhi-kolkata-flights.html',
                text: 'Delhi Kolkata flights'
            },
            {
                url: '/flight-schedule/pune-new-delhi-flights.html',
                text: 'Pune Delhi flights'
            },
            {
                url: '/flight-schedule/bangalore-new-delhi-flights.html',
                text: 'Bangalore Delhi flights'
            },
            {
                url: '/flight-schedule/mumbai-bangalore-flights.html',
                text: 'Mumbai Bangalore flights'
            },
            {
                url: '/flight-schedule/chennai-new-delhi-flights.html',
                text: 'Chennai Delhi flights'
            },
            {
                url: '/flight-schedule/kolkata-new-delhi-flights.html',
                text: 'Kolkata Delhi flights'
            },
            {
                url: '/flight-schedule/new-delhi-mumbai-flights.html',
                text: 'Delhi Mumbai flights'
            },
            {
                url: '/flight-schedule/new-delhi-bangalore-flights.html',
                text: 'Delhi Bangalore flights'
            },
            {
                url: '/flight-schedule/new-delhi-dubai-flights.html',
                text: 'Delhi Dubai flights'
            },
            {
                url: '/flight-schedule/mumbai-dubai-flights.html',
                text: 'Mumbai Dubai flights'
            },
            {
                url: '/flight-schedule/new-delhi-bangkok-flights.html',
                text: 'Delhi Bangkok flights'
            },
            {
                url: '/flight-schedule/new-delhi-singapore-flights.html',
                text: 'Delhi Singapore flights'
            },
            {
                url: '/flight-schedule/mumbai-goa-flights.html',
                text: 'Mumbai Goa flights'
            }
        ],
        undefined: [],
        AE: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        SA: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        OM: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        KW: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        QA: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        BH: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ]
    },
    popularAirlines: {
        pages: ['flights', 'home'],
        text: 'Popular Airlines',
        AE: [
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            }, {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            }
        ],
        SA: [
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            }, {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            }
        ],
        BH: [
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            }, {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            }
        ],
        QA: [
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            }, {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            }
        ],
        OM: [
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            }, {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            }
        ],
        KW: [
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            }, {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            }
        ],
    },
    internationalAirlines: {
        pages: ['flights', 'home'],
        text: 'Popular Airlines Old',
        AE: [
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas Airlines Airlines'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'Pegasus Airlines'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Vistara Airlines'
            },
            {
                url: '/flight-booking/air-arabia-airlines.html',
                text: 'Air Arabia'
            },
            {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Airblue'
            },
            {
                url: '/flight-booking/air-india-airlines.html',
                text: 'Air India'
            },
            {
                url: '/flight-booking/air-india-express-airlines.html',
                text: 'Air India Express'
            },
            {
                url: '/flight-booking/british-airways-airlines.html',
                text: 'British Airways'
            },
            {
                url: '/flight-booking/qatar-airways-airlines.html',
                text: 'Qatar Airways'
            },
            {
                url: '/flight-booking/cebu-pacific-air-airlines.html',
                text: 'Cebu Pacific'
            },
            {
                url: '/flight-booking/emirates-airlines.html',
                text: 'Emirates'
            },
            {
                url: '/flight-booking/etihad-airways-airlines.html',
                text: 'Etihad Airways'
            },
            {
                url: '/flight-booking/flydubai-airlines.html',
                text: 'Flydubai'
            },
            {
                url: '/flight-booking/goair-airlines.html',
                text: 'Go Air'
            },
            {
                url: '/flight-booking/gulf-air-airlines.html',
                text: 'Gulf Air'
            },
            {
                url: '/flight-booking/indigo-airlines.html',
                text: 'Indigo'
            },
            {
                url: '/flight-booking/oman-air-airlines.html',
                text: 'Oman Air'
            },
            {
                url: '/flight-booking/pakistan-international-airlines.html',
                text: 'PIA Airlines'
            },
            {
                url: '/flight-booking/philippine-airlines.html',
                text: 'Philippine Airlines'
            },
            {
                url: '/flight-booking/spicejet-airlines.html',
                text: 'Spicejet'
            },
            {
                url: '/flight-booking/turkish-airlines.html',
                text: 'Turkish Airlines'
            },
            {
                url: '/flight-booking/wizz-air-airlines.html',
                text: 'Wizz Air'
            }
        ],
        SA: [
            {
                url: '/flight-booking/lufthansa-airlines.html',
                text: 'Lufthansa Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas Airlines Airlines'
            },
            {
                url: '/flight-booking/cubana-de-aviacion-airlines.html',
                text: 'Cubana de Aviacion Airlines'
            },
            {
                url: '/flight-booking/cathay-pacific-airlines.html',
                text: 'Cathay Pacific Airlines'
            },
            {
                url: '/flight-booking/ryanair-airlines.html',
                text: 'Ryanair Airlines'
            },
            {
                url: '/flight-booking/pegasus-airlines.html',
                text: 'Pegasus Airlines'
            },
            {
                url: '/flight-booking/kuwait-airways-airlines.html',
                text: 'Kuwait Airways'
            },
            {
                url: '/flight-booking/airasia-india-airlines.html',
                text: 'Airasia India Airlines'
            },
            {
                url: '/flight-booking/american-airlines.html',
                text: 'American Airlines'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/easyjet-airlines.html',
                text: 'easyJet Airlines'
            },
            {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Asiana Airlines'
            },
            {
                url: '/flight-booking/united-airlines.html',
                text: 'United Airlines'
            },
            {
                url: '/flight-booking/aeroflot-russian-airlines.html',
                text: 'Aeroflot Russian Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/vistara-airlines.html',
                text: 'Vistara Airlines'
            }
        ],
        BH: [
            {
                url: '/flight-booking/lufthansa-airlines.html',
                text: 'Lufthansa Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas Airlines Airlines'
            },
            {
                url: '/flight-booking/cubana-de-aviacion-airlines.html',
                text: 'Cubana de Aviacion Airlines'
            },
            {
                url: '/flight-booking/cathay-pacific-airlines.html',
                text: 'Cathay Pacific Airlines'
            },
            {
                url: '/flight-booking/ryanair-airlines.html',
                text: 'Ryanair Airlines'
            },
            {
                url: '/flight-booking/pegasus-airlines.html',
                text: 'Pegasus Airlines'
            },
            {
                url: '/flight-booking/kuwait-airways-airlines.html',
                text: 'Kuwait Airways'
            },
            {
                url: '/flight-booking/airasia-india-airlines.html',
                text: 'Airasia India Airlines'
            },
            {
                url: '/flight-booking/american-airlines.html',
                text: 'American Airlines'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/easyjet-airlines.html',
                text: 'easyJet Airlines'
            },
            {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Asiana Airlines'
            },
            {
                url: '/flight-booking/united-airlines.html',
                text: 'United Airlines'
            },
            {
                url: '/flight-booking/aeroflot-russian-airlines.html',
                text: 'Aeroflot Russian Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/vistara-airlines.html',
                text: 'Vistara Airlines'
            }
        ],
        QA: [
            {
                url: '/flight-booking/lufthansa-airlines.html',
                text: 'Lufthansa Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas Airlines Airlines'
            },
            {
                url: '/flight-booking/cubana-de-aviacion-airlines.html',
                text: 'Cubana de Aviacion Airlines'
            },
            {
                url: '/flight-booking/cathay-pacific-airlines.html',
                text: 'Cathay Pacific Airlines'
            },
            {
                url: '/flight-booking/ryanair-airlines.html',
                text: 'Ryanair Airlines'
            },
            {
                url: '/flight-booking/pegasus-airlines.html',
                text: 'Pegasus Airlines'
            },
            {
                url: '/flight-booking/kuwait-airways-airlines.html',
                text: 'Kuwait Airways'
            },
            {
                url: '/flight-booking/airasia-india-airlines.html',
                text: 'Airasia India Airlines'
            },
            {
                url: '/flight-booking/american-airlines.html',
                text: 'American Airlines'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/easyjet-airlines.html',
                text: 'easyJet Airlines'
            },
            {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Asiana Airlines'
            },
            {
                url: '/flight-booking/united-airlines.html',
                text: 'United Airlines'
            },
            {
                url: '/flight-booking/aeroflot-russian-airlines.html',
                text: 'Aeroflot Russian Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/vistara-airlines.html',
                text: 'Vistara Airlines'
            }
        ],
        OM: [
            {
                url: '/flight-booking/lufthansa-airlines.html',
                text: 'Lufthansa Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas Airlines Airlines'
            },
            {
                url: '/flight-booking/cubana-de-aviacion-airlines.html',
                text: 'Cubana de Aviacion Airlines'
            },
            {
                url: '/flight-booking/cathay-pacific-airlines.html',
                text: 'Cathay Pacific Airlines'
            },
            {
                url: '/flight-booking/ryanair-airlines.html',
                text: 'Ryanair Airlines'
            },
            {
                url: '/flight-booking/pegasus-airlines.html',
                text: 'Pegasus Airlines'
            },
            {
                url: '/flight-booking/kuwait-airways-airlines.html',
                text: 'Kuwait Airways'
            },
            {
                url: '/flight-booking/airasia-india-airlines.html',
                text: 'Airasia India Airlines'
            },
            {
                url: '/flight-booking/american-airlines.html',
                text: 'American Airlines'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/easyjet-airlines.html',
                text: 'easyJet Airlines'
            },
            {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Asiana Airlines'
            },
            {
                url: '/flight-booking/united-airlines.html',
                text: 'United Airlines'
            },
            {
                url: '/flight-booking/aeroflot-russian-airlines.html',
                text: 'Aeroflot Russian Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/vistara-airlines.html',
                text: 'Vistara Airlines'
            }
        ],
        KW: [
            {
                url: '/flight-booking/lufthansa-airlines.html',
                text: 'Lufthansa Airlines'
            },
            {
                url: '/flight-booking/singapore-airlines.html',
                text: 'Singapore Airlines'
            },
            {
                url: '/flight-booking/srilankan-airlines.html',
                text: 'SriLankan Airlines'
            },
            {
                url: '/flight-booking/kenya-airways-airlines.html',
                text: 'Kenya Airways'
            },
            {
                url: '/flight-booking/royal-jordanian-airlines.html',
                text: 'Royal Jordanian Airlines'
            },
            {
                url: '/flight-booking/klm-royal-dutch-airlines.html',
                text: 'KLM Royal Dutch Airlines'
            },
            {
                url: '/flight-booking/flynas-airlines.html',
                text: 'FlyNas Airlines Airlines'
            },
            {
                url: '/flight-booking/cubana-de-aviacion-airlines.html',
                text: 'Cubana de Aviacion Airlines'
            },
            {
                url: '/flight-booking/cathay-pacific-airlines.html',
                text: 'Cathay Pacific Airlines'
            },
            {
                url: '/flight-booking/ryanair-airlines.html',
                text: 'Ryanair Airlines'
            },
            {
                url: '/flight-booking/pegasus-airlines.html',
                text: 'Pegasus Airlines'
            },
            {
                url: '/flight-booking/kuwait-airways-airlines.html',
                text: 'Kuwait Airways'
            },
            {
                url: '/flight-booking/airasia-india-airlines.html',
                text: 'Airasia India Airlines'
            },
            {
                url: '/flight-booking/american-airlines.html',
                text: 'American Airlines'
            },
            {
                url: '/flight-booking/jazeera-airways-airlines.html',
                text: 'Jazeera Airways'
            },
            {
                url: '/flight-booking/easyjet-airlines.html',
                text: 'easyJet Airlines'
            },
            {
                url: '/flight-booking/asiana-airlines.html',
                text: 'Asiana Airlines'
            },
            {
                url: '/flight-booking/united-airlines.html',
                text: 'United Airlines'
            },
            {
                url: '/flight-booking/aeroflot-russian-airlines.html',
                text: 'Aeroflot Russian Airlines'
            },
            {
                url: '/flight-booking/ethiopian-air-airlines.html',
                text: 'Ethiopian Air'
            },
            {
                url: '/flight-booking/middle-east-airlines.html',
                text: 'Middle East Airlines'
            },
            {
                url: '/flight-booking/serene-air-airlines.html',
                text: 'Serene Air'
            },
            {
                url: '/flight-booking/saudi-arabian-air-airlines.html',
                text: 'Saudi Arabian Airlines'
            },
            {
                url: '/flight-booking/air-france-airlines.html',
                text: 'Air France'
            },
            {
                url: '/flight-booking/egyptair-airlines.html',
                text: 'Egyptair Airlines'
            },
            {
                url: '/flight-booking/vistara-airlines.html',
                text: 'Vistara Airlines'
            }
        ]
    },
    popularHotels: {
        pages: ['flights', 'home'],
        text: 'Popular Hotels',
        AE: [{ url: '/hotels', text: 'Hotels' }],
        SA: [{ url: '/hotels', text: 'Hotels' }],
        BH: [{ url: '/hotels', text: 'Hotels' }],
        QA: [{ url: '/hotels', text: 'Hotels' }],
        OM: [{ url: '/hotels', text: 'Hotels' }]
    },
    otherTools: {
        pages: ['flights', 'home'],
        text: 'Other Tools',
        IN: [
            { url: '/', text: 'Flight Booking' },
            {
                url: '/flights',
                text: 'Book cheap flights for travel within India and abroad'
            },
            { url: '/hotels/india/' },
            { url: '/status', text: 'Check Flight Status' },
            { url: '/trains' },
            { url: '/trains/pnr' },
            { url: '/tourism/train/tatkal-booking.html', text: 'Tatkal booking' },
            { url: '/trains/calendar', text: 'IRCTC Seat Availability' },
            { url: '/trains/stations/list', text: 'List of stations' },
            { url: '/trains/list', text: 'List of trains' },
            {
                url: '/flight-tickets/cheap-flight-air-tickets-domestic.html',
                text: 'Domestic Air Tickets'
            },
            { url: '/flight-schedule/flight-schedules-domestic.html' },
            { url: '/flight-booking/domestic-airlines.html' },
            {
                url: '/flight-tickets/cheap-flight-air-tickets-international.html',
                text: 'International Air Tickets'
            },
            { url: '/flight-schedule/flight-schedules-international.html' },
            { url: '/flight-booking/international-airlines.html' },
            { url: 'https://www.cleartrip.com/tourism/packages/' }
        ],
        undefined: [],

        SA: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: 'https://www.flightstatus.com/v2',
                text: 'Flight Status'
            },
            { url: '/hotels/', text: 'Hotels' },
            {
                url: '/hotels/united-arab-emirates/dubai/',
                text: 'Hotels in Dubai'
            },
            {
                url: '/hotels/united-arab-emirates/abu-dhabi/',
                text: 'Hotels in Abu Dhabi'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/tourism/airports/',
                text: 'Domestic Airports'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/tourism/airports/international-airports.html',
                text: 'International Airports'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        OM: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: 'https://www.flightstatus.com/v2',
                text: 'Flight Status'
            },
            { url: '/hotels/', text: 'Hotels' },
            {
                url: '/hotels/united-arab-emirates/dubai/',
                text: 'Hotels in Dubai'
            },
            {
                url: '/hotels/united-arab-emirates/abu-dhabi/',
                text: 'Hotels in Abu Dhabi'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/tourism/airports/',
                text: 'Domestic Airports'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/tourism/airports/international-airports.html',
                text: 'International Airports'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        KW: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: 'https://www.flightstatus.com/v2',
                text: 'Flight Status'
            },
            { url: '/hotels/', text: 'Hotels' },
            {
                url: '/hotels/united-arab-emirates/dubai/',
                text: 'Hotels in Dubai'
            },
            {
                url: '/hotels/united-arab-emirates/abu-dhabi/',
                text: 'Hotels in Abu Dhabi'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/tourism/airports/',
                text: 'Domestic Airports'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/tourism/airports/international-airports.html',
                text: 'International Airports'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        QA: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: 'https://www.flightstatus.com/v2',
                text: 'Flight Status'
            },
            { url: '/hotels/', text: 'Hotels' },
            {
                url: '/hotels/united-arab-emirates/dubai/',
                text: 'Hotels in Dubai'
            },
            {
                url: '/hotels/united-arab-emirates/abu-dhabi/',
                text: 'Hotels in Abu Dhabi'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/tourism/airports/',
                text: 'Domestic Airports'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/tourism/airports/international-airports.html',
                text: 'International Airports'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        BH: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: 'https://www.flightstatus.com/v2',
                text: 'Flight Status'
            },
            { url: '/hotels/', text: 'Hotels' },
            {
                url: '/hotels/united-arab-emirates/dubai/',
                text: 'Hotels in Dubai'
            },
            {
                url: '/hotels/united-arab-emirates/abu-dhabi/',
                text: 'Hotels in Abu Dhabi'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/tourism/airports/',
                text: 'Domestic Airports'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/tourism/airports/international-airports.html',
                text: 'International Airports'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ],
        AE: [
            {
                url: '/flights',
                text: 'Domestic Flights'
            },
            {
                url: '/international-flights',
                text: 'International Flights'
            },
            {
                url: '/flight-schedule/flight-schedules-domestic.html',
                text: 'Domestic Flight Schedule'
            },
            {
                url: '/flight-schedule/flight-schedules-international.html',
                text: 'International Flight Schedule'
            },
            {
                url: '/flight-booking/domestic-airlines.html',
                text: 'Domestic Airlines'
            },
            {
                url: '/flight-booking/international-airlines.html',
                text: 'International Airlines'
            },
            {
                url: '/tourism/airports/',
                text: 'Domestic Airports'
            },
            {
                url: '/tourism/airports/international-airports.html',
                text: 'International Airports'
            },
            {
                url: '/flight-schedule/india-dubai-flights.html',
                text: 'India to Dubai flights'
            },
            {
                url: '/flight-schedule/dubai-india-flights.html',
                text: 'Dubai to India flights'
            }
        ]
    },
    countries: {
        pages: ['flights', 'home'],
        text: 'Countries',
        AE: [
            {
                url: 'https://www.cleartrip.ae',
                text: 'The United Arab Emirates'
            },
            {
                url: 'https://www.cleartrip.sa',
                text: 'Kingdom of Saudi Arabia'
            },
            {
                url: 'https://www.cleartrip.bh',
                text: 'Bahrain'
            },
            {
                url: 'https://www.cleartrip.com.kw',
                text: 'Kuwait'
            },
            {
                url: 'https://www.cleartrip.qa',
                text: 'Qatar'
            },
            {
                url: 'https://www.cleartrip.om',
                text: 'Sultanate of Oman'
            }
        ],
        SA: [
            {
                url: 'https://www.cleartrip.ae',
                text: 'The United Arab Emirates'
            },
            {
                url: 'https://www.cleartrip.sa',
                text: 'Kingdom of Saudi Arabia'
            },
            {
                url: 'https://www.cleartrip.bh',
                text: 'Bahrain'
            },
            {
                url: 'https://www.cleartrip.com.kw',
                text: 'Kuwait'
            },
            {
                url: 'https://www.cleartrip.qa',
                text: 'Qatar'
            },
            {
                url: 'https://www.cleartrip.om',
                text: 'Sultanate of Oman'
            }
        ],
        OM: [
            {
                url: 'https://www.cleartrip.ae',
                text: 'The United Arab Emirates'
            },
            {
                url: 'https://www.cleartrip.sa',
                text: 'Kingdom of Saudi Arabia'
            },
            {
                url: 'https://www.cleartrip.bh',
                text: 'Bahrain'
            },
            {
                url: 'https://www.cleartrip.com.kw',
                text: 'Kuwait'
            },
            {
                url: 'https://www.cleartrip.qa',
                text: 'Qatar'
            },
            {
                url: 'https://www.cleartrip.om',
                text: 'Sultanate of Oman'
            }
        ],
        KW: [
            {
                url: 'https://www.cleartrip.ae',
                text: 'The United Arab Emirates'
            },
            {
                url: 'https://www.cleartrip.sa',
                text: 'Kingdom of Saudi Arabia'
            },
            {
                url: 'https://www.cleartrip.bh',
                text: 'Bahrain'
            },
            {
                url: 'https://www.cleartrip.com.kw',
                text: 'Kuwait'
            },
            {
                url: 'https://www.cleartrip.qa',
                text: 'Qatar'
            },
            {
                url: 'https://www.cleartrip.om',
                text: 'Sultanate of Oman'
            }
        ],
        QA: [
            {
                url: 'https://www.cleartrip.ae',
                text: 'The United Arab Emirates'
            },
            {
                url: 'https://www.cleartrip.sa',
                text: 'Kingdom of Saudi Arabia'
            },
            {
                url: 'https://www.cleartrip.bh',
                text: 'Bahrain'
            },
            {
                url: 'https://www.cleartrip.com.kw',
                text: 'Kuwait'
            },
            {
                url: 'https://www.cleartrip.qa',
                text: 'Qatar'
            },
            {
                url: 'https://www.cleartrip.om',
                text: 'Sultanate of Oman'
            }
        ],
        BH: [
            {
                url: 'https://www.cleartrip.ae',
                text: 'The United Arab Emirates'
            },
            {
                url: 'https://www.cleartrip.sa',
                text: 'Kingdom of Saudi Arabia'
            },
            {
                url: 'https://www.cleartrip.bh',
                text: 'Bahrain'
            },
            {
                url: 'https://www.cleartrip.com.kw',
                text: 'Kuwait'
            },
            {
                url: 'https://www.cleartrip.qa',
                text: 'Qatar'
            },
            {
                url: 'https://www.cleartrip.om',
                text: 'Sultanate of Oman'
            }
        ]
    }
}
