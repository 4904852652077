import React, { useState } from 'react'
import Slider from 'react-slick'
import HotelCouponTag from '@components/HotelsCrossSell/HotelCouponTag'
import HotelsOfferIcon from '@assests/svg/HotelsOfferIcon'
import IFrame from '@lib/IFrame'
import { isEmpty } from '@utils/genralUtils'

const Cvpwidget = (props) => {
    const [iframeLink, setIframeLink] = useState<String | null>(null)
    const [showIframe, setShowIframe] = useState(false)
    const { offerData } = props

    const handleIframe = (link: string, title: string) => {
        setIframeLink(link)
        setShowIframe(true)
    }

    return (
        <>
            {!isEmpty(offerData) && (
                <div className={`cvpWidgetConatiner`} style={{ height: "315px", padding: "12px" }}>
                    {
                        <p className="fw-600 fs-20 lh-24 pl-4 pr-4" style={{ padding: "0 4px 4px 4px" }}>Exclusive Offers</p>
                    }

                    <div>
                        <Slider
                            infinite={true}
                            speed={500}
                            slidesToShow={1.5}
                            initialSlide={-0.5}
                            slidesToScroll={1}
                            arrows={false}
                        >
                            {offerData.map((card: any) => {
                                return (
                                    <div key={card.title}
                                        className="slider-card bg-neutral-100 p-4 mt-4 br-12 h-194"
                                        onClick={() => {
                                            handleIframe(card.targetLink, card.title)
                                        }}
                                    >
                                        <div style={{ backgroundColor: "#FFFFFF", padding: "12px", borderRadius: "12px", height: "250px" }}>
                                            <div className='mb-10' style={{ borderRadius: "50%", backgroundColor: "#ffd8cb", width: "35px", height: "35px" }}><img src={card.icon} alt="icon" style={{ width: "35px", height: "35px", padding: "5px" }} className="mb-2" /></div>
                                            <p className="fs-18 fw-600 lh-22 c-neutral-900 mb-2">{card.title} </p>
                                            <p className="fs-16 fw-500 c-black-60 lh-copy">
                                                {card.description}
                                            </p>
                                            {card.code &&
                                                <div className="mt-4" style={{ bottom: "12px", position: "fixed" }}>
                                                    <HotelCouponTag
                                                        couponCode={card.code}
                                                        // copy={true}
                                                        couponIcon={<HotelsOfferIcon />}
                                                    // onCopy={handleCopyToClipboard}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {/* {card.learnMoreLinkText &&
                                            card.learnMoreLink &&
                                            card.learnMoreLinkText !== '' &&
                                            card.learnMoreLink !== '' && (
                                                <Container
                                                    className="flex mt-3 c-link-100 link-container"
                                                    onClick={() => {
                                                        handleIframe(card.learnMoreLink, card.title)
                                                    }}
                                                >
                                                    <p className="lh-16 fs-12 fw-600">{card.learnMoreLinkText}</p>
                                                </Container>
                                            )} */}
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            )}
            {showIframe && iframeLink && (

                <IFrame
                    URL={iframeLink}
                    title="Offers"
                    message="Loading exclusive offers"
                    isOpen={showIframe}
                    sheetTitle="Offers"
                    onClose={() => setShowIframe(false)}
                />

            )}
        </>
    )
}

export default Cvpwidget
