import Analytics from "@root/ui/utils/Analytics"
import { checkDeviceType } from '@utils/appAgent'


export const sendClevertapUIActionEvent = ({ actionName, actionType = 'BUTTON', page, analyticsEventName = '', ...additionalData }) => {
  let device = checkDeviceType()
  if (device === 'iPhoneApp') {
    device = 'iOS'
  } else if (device === 'AndroidApp') {
    device = 'Android'
  } else {
    device = 'pwa'
  }

  let fullPayload = {
    action_name: actionName,
    action_type: actionType,
    source: device,
    domain: window.location.host,
    source_page_name: page,
    ...additionalData
  }
  Analytics.event(analyticsEventName ? analyticsEventName : 'Account_UI_Action', fullPayload)
}
