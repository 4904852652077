import React from 'react'
import { isEmpty } from '@ui/utils'

export const ParagraphText = ({ data = {} }) => {
    const renderText = () => {
        let { value, placeHoldersList = [] } = data
        if (value && !isEmpty(placeHoldersList)) {
            value = placeHoldersList.reduce((text, item) => {
                let { type = '', placeHolder = '', replaceText = '', url = '' } = item || {}
                if (type === 'link') {
                    return text.replace(placeHolder, `<a href=${url} class="c-blue">${replaceText}</a>`)
                }
                if (type === 'bold') {
                    return text.replace(placeHolder, `<span class="fw-700">${replaceText}</span>`)
                }
            }, value)
        }
        return value
    }
    return <p className="fs-14 lh-copy c-black-60" dangerouslySetInnerHTML={{ __html: renderText() }}></p>
}

export const ListCreator = ({ data }) => {
    return (
        <ul className="pt-2">
            {data.values.map(listItem => {
                if (listItem.type === 'text') {
                    return (
                        <li className="pt-1 flex fs-14 lh-copy c-black-60">
                            <div>-</div>&nbsp;
                            <ParagraphText data={listItem} />
                        </li>
                    )
                }
            })}
        </ul>
    )
}

const HomePageFaq = ({ data }) => {
    if (!data || data.length <= 1) return null;
    return (
        <>
            <h2 className="fs-16 lh-title fw-600 c-neutral-900 pt-4">FAQs</h2>
            {data.map((faq , index) => {
                return (
                    <div key={index}>
                        <h2 className="fs-16 lh-title fw-500 pt-12 pb-10">{faq.heading}</h2>
                        {faq.values.map(item => {
                            if (item.type === 'text') {
                                return <ParagraphText data={item} />
                            }
                            if (item.type === 'list') {
                                return <ListCreator data={item} />
                            }
                            if (item.type === 'space') {
                                return <div className="pt-16" />
                            }
                        })}
                    </div>
                )
            })}
        </>
    )
}

export default HomePageFaq
